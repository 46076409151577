import { defineStore } from 'pinia'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { loginUser, updatePassword, updateUser, type user, userLogin, userRegister } from '@/interfaces/auth'
import { get, patch, post, put } from '@/modules/asyncData'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { JWT } from '@/interfaces/basic'
import { competitorStore } from '@/stores/competitorStore'

export const authStore = defineStore('auth', () => {
  const router = useRouter()
  const route = useRoute()
  const isAuth = ref<boolean>(false)
  const jwtToken = ref<string>('')
  const user: user = reactive<user>({
    emailStatus: '',
    name: '',
    id: '',
    email: '',
    company: undefined,
    role: undefined,
    plan: undefined,
    planExpires: undefined,
    trial: undefined
  })
  const affiliate = ref<string | null>(null)

  const expires = ref<number>(0)

  const refreshValue = moment.duration(1, 'hours').asSeconds()

  const setJwt = (token: string) => {
    jwtToken.value = token
    Object.assign(user, jwtDecode<JWT>(token).sub)
    expires.value = jwtDecode<JWT>(token).exp
    isAuth.value = true
  }

  const checkJwt = async (force = false) => {
    if (expires.value > 0 && expires.value < moment().unix()) {
      jwtToken.value = ''
      expires.value = 0
      isAuth.value = false
    } else if (force || (expires.value > 0 && expires.value + refreshValue < moment().unix())) {
      console.log('updating login')
      try {
        const r = await get<loginUser>('auth/refresh')
        jwtToken.value = r.data.token
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
        setJwt(r.data.token)
      } catch (e) {
        logout()
        const route = useRoute()
        const router = useRouter()
        if (route.meta.auth) await router.push({ name: 'login' })
      }
    } else {
      const competitor = competitorStore()
      if (competitor.lastUpdate + 60 < moment().unix()) competitor.loadAll()
      axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
    }
  }

  const login = async (userInput: userLogin): Promise<void> => {
    return new Promise((resolve, reject) => {
      post<loginUser>('auth/login', userInput)
        .then(async r => {
          const competitors = competitorStore()
          jwtToken.value = r.data.token
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          setJwt(r.data.token)
          await competitors.loadAll()
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const updateAccount = async (user: updateUser): Promise<void> => {
    return new Promise((resolve, reject) => {
      patch<loginUser>('settings/account', user)
        .then(r => {
          jwtToken.value = r.data.token
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          setJwt(r.data.token)
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const updatePassword = async (user: updatePassword): Promise<void> => {
    return new Promise((resolve, reject) => {
      patch<loginUser>('settings/password', user)
        .then(r => {
          jwtToken.value = r.data.token
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          setJwt(r.data.token)
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const logout = async (): Promise<void> => {
    isAuth.value = false
    Object.assign(user, {
      emailStatus: '',
      name: '',
      lastName: '',
      id: '',
      email: '',
      trial: 0,
      currentPlan: undefined,
      company: undefined,
      role: undefined
    })
    jwtToken.value = ''
    axios.defaults.headers.common.Authorization = undefined
    await router.push({ name: 'login' })
  }

  const register = async (user: userRegister): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      user.affiliate = affiliate.value
      put<null>('auth/register', user)
        .then(r => {
          console.log(r)
          isAuth.value = false
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const resendEmail = async (email: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      put<null>('auth/resendEmail', { email })
        .then(unused => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const forgotPassword = async (userName: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      put<null>('auth/forgot', { userName })
        .then(() => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const resetPassword = async (password: string, token: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      post<loginUser>('auth/reset', { password, token })
        .then((r) => {
          jwtToken.value = r.data.token
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          setJwt(r.data.token)
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const resources = computed(() => {
    if (((user.planExpires !== null && user.planExpires === undefined) || user.planExpires <= moment().unix()) && user.trial && user.trial <= moment().unix()) {
      return {
        competitors: 0,
        routines: 0
      }
    } else {
      return user.plan === 'pro'
        ? {
            competitors: 25,
            routines: 10
          }
        : {
            competitors: 5,
            routines: 5
          }
    }
  })

  return { affiliate, isAuth, user, jwtToken, checkJwt, login, logout, register, forgotPassword, resetPassword, setJwt, resendEmail, updateAccount, updatePassword, resources }
},
{
  persist: {
    paths: ['isAuth', 'jwtToken', 'user']
  }
}
)
