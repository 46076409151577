<script setup lang="ts">
import { authStore } from '@/stores/authStore'
import moment from 'moment'

const auth = authStore()
let diffDays = -1
if (auth.user.trial && auth.user.trial > moment().unix()) {
  diffDays = moment.unix(auth.user.trial).diff(moment(), 'd')
}
</script>

<template>
  <div v-if="(auth.user.plan === null || auth.user.plan === undefined || auth.user.planExpires > moment().unix()) && auth.user?.trial > moment().unix() && diffDays !== -1" class="w-full t-0 b-0 px-5 py-4 bg-trial-background border-b border-trial-border flex flex-wrap justify-center items-center text-trial-text font-medium">
    {{  $tc('navbar.trial', diffDays) }} <router-link :to="{name: 'billing'}" class="font-bold underline ml-1">{{ $t('navbar.upgrade') }}</router-link>
  </div>
  <div v-else-if="(auth.user.plan === null || auth.user.plan === undefined || auth.user.planExpires > moment().unix())" class="w-full t-0 b-0 px-5 py-4 bg-trial-backgroundExpired border-b border-trial-borderExpired flex justify-center items-center text-trial-textExpired font-medium flex-wrap">
    {{  $tc('navbar.trialExpired') }} <router-link :to="{name: 'billing'}" class="font-bold underline ml-1">{{ $t('navbar.upgradeExpired') }}</router-link>
  </div>
</template>

<style scoped lang="scss">

</style>
