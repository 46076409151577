<script setup lang="ts">
import { authStore } from '@/stores/authStore'
import { onMounted, ref, watch, getCurrentInstance } from 'vue'
import { competitorStore } from '@/stores/competitorStore'
import { useRoute } from 'vue-router'
import { configStore } from '@/stores/configStore'
import NotificationComp from '@/components/basic/NotificationComp.vue'

const auth = authStore()
const config = configStore()
const competitor = competitorStore()

setInterval(auth.checkJwt, 10000)
onMounted(async () => {
  auth.checkJwt()
  competitor.loadAll()
  config.loadPrices()
})

const scrollRef = ref()
const route = useRoute()
watch(() => route.path, () => {
  scrollRef?.value.scrollTo(0, 0)
})

</script>

<template>
  <div class="flex flex-col min-h-screen text-font-normal dark:text-white">
    <notification-comp />
    <div class="flex flex-row h-screen">
      <RouterView v-slot="{ Component }" class="flex-grow-0" name="navBar">
        <Transition name="fade">
          <component :is="Component"></component>
        </Transition>
      </RouterView>
      <div class="flex flex-col flex-grow overflow-x-hidden" ref="scrollRef">
        <RouterView name="trial" />
        <RouterView v-slot="{ Component }" class="flex flex-grow relative">
          <template v-if="Component">
            <Transition :name="$route.meta.transition" mode="out-in">
              <Suspense>
                <component :is="Component" :key="$route.params?.id"></component>
              </Suspense>
            </Transition>
          </template>
        </RouterView>
      </div>
    </div>
    <RouterView name="footer" class="flex flex-row justify-center" />
    <vue-progress-bar />
  </div>
</template>

<style lang="scss">
.slide-right-enter-active,
.slide-left-enter-active,
.fade-enter-active,
.slide-right-leave-active,
.slide-left-leave-active,
.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-right-enter-from,
.slide-left-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
.slide-left-enter-from,
.slide-right-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}
</style>
