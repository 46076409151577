<script setup lang="ts">
import { ref, reactive, watch, computed, getCurrentInstance, nextTick } from 'vue'
import { competitorType, screenshotType } from '@/interfaces/competitors'
import { get } from '@/modules/asyncData'
import ScreenshotComp from '@/components/screenshots/ScreenshotComp.vue'
import desktopIcon from '@material-design-icons/svg/round/desktop_mac.svg'
import mobileIcon from '@material-design-icons/svg/round/smartphone.svg'
import calendarIcon from '@material-design-icons/svg/round/date_range.svg'
import backspaceIcon from '@material-design-icons/svg/round/undo.svg'
import DateRangePicker from 'vue3-daterange-picker'
import moment from 'moment'

interface propType {
  routineId: string,
  competitor: competitorType,
  mobile?: boolean,
  desktop?: boolean
}
const props = defineProps<propType>()

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const screenshots = reactive<screenshotType[]>([])

const loading = ref<boolean>(false)

watch(() => props.routineId, async (first, unused) => {
  loading.value = true
  $Progress.start()
  screenshots.splice(0)
  if (!first) return
  try {
    const result = await get<screenshotType[]>('screenshots', { routine: first })
    screenshots.push(...result.data)
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
  }
  loading.value = false
})

const filterFavorites = ref<boolean>(false)
const filterMobile = ref<boolean>(props.mobile)
const filterDesktop = ref<boolean>(props.desktop)
const filterDate = reactive({ startDate: null, endDate: null })

const screenshotsFiltered = computed(() => {
  let filteredScreenshots = screenshots

  if (filterMobile.value && filterDesktop.value) {
    filteredScreenshots = filteredScreenshots.filter(screenshot => {
      return screenshot.mobile || screenshot.desktop
    })
  } else if (filterMobile.value) {
    filteredScreenshots = filteredScreenshots.filter(screenshot => {
      return screenshot.mobile
    })
  } else if (filterDesktop.value) {
    filteredScreenshots = filteredScreenshots.filter(screenshot => {
      return screenshot.desktop
    })
  }

  if (filterFavorites.value) {
    filteredScreenshots = filteredScreenshots.filter(screenshot => {
      return screenshot.favorite
    })
  }

  if (filterDate.startDate && filterDate.endDate) {
    const startDate = moment.unix(filterDate.startDate.getTime() / 1000).startOf('day').unix() * 1000
    const endDate = moment.unix(filterDate.endDate.getTime() / 1000).endOf('day').unix() * 1000
    filteredScreenshots = filteredScreenshots.filter(screenshot => {
      return screenshot.createdAt >= startDate && screenshot.createdAt <= endDate
    })
  }
  // @ts-ignore
  return filteredScreenshots.sort((a, b) => a.createdAt <= b.createdAt)
})

</script>

<template>
  <div class="mt-8 w-full" v-if="routineId">
    <div class="w-full border-b border-gray-50 dark:border-gray-800 pb-2 mb-7 flex flex-row items-center justify-between" v-if="!loading">
      <div class="text-xs font-bold uppercase text-gray-600">{{ $t('competitor.tabs.website.screenshots.title') }} ({{ screenshots.length }})</div>
      <div class="flex flex-row gap-4">
        <backspaceIcon class="w-6 h-8 py-1.5 text-gray-500 hover:text-logo" v-if="filterDate.startDate" @click="() => {$refs.picker.start = null; $refs.picker.end = null; filterDate.startDate = null; filterDate.endDate = null}"/>
        <div class="cursor-pointer relative flex flex-row  px-2 py-1 rounded border border-input-border hover:bg-gray-25 dark:hover:bg-gray-800 group">
          <div class="flex flex-row items-center gap-2" @click.prevent.stop="$refs.picker.togglePicker(true)">
            <calendarIcon class="w-6 h-6 scale-75" :class="filterDate.startDate && filterDate.endDate ? 'text-logo group-hover:text-gray-500' : 'text-gray-500 group-hover:text-logo'"></calendarIcon>
            <div class="font-sm text-normal hidden lg:block">{{ $t('competitor.forms.calendar') }}</div>
          </div><date-range-picker
            ref="picker"
            opens="center"
            :maxDate="new Date()"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :ranges="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            :dateRange="{startDate: null, endDate: null}"
            :linkedCalendars="false"
            @select="(date) => {
              filterDate.startDate = date.startDate
              filterDate.endDate = date.endDate
              $refs.picker.togglePicker(false)
            }"
          >
          </date-range-picker>
        </div>
        <div v-if="mobile" @click="filterDesktop = !filterDesktop" class="cursor-pointer flex flex-row items-center gap-2 px-2 py-1 rounded border border-input-border hover:bg-gray-25 dark:hover:bg-gray-800 group">
          <desktopIcon class="w-6 h-6 scale-75" :class="filterDesktop ? 'text-logo group-hover:text-gray-500' : 'text-gray-500 group-hover:text-logo'"></desktopIcon>
          <div class="font-sm text-normal hidden lg:block">{{ $t('competitor.forms.desktop') }}</div>
        </div>
        <div v-if="desktop" @click="filterMobile = !filterMobile" class="cursor-pointer flex flex-row items-center gap-2 px-2 py-1 rounded border border-input-border hover:bg-gray-25 dark:hover:bg-gray-800 group">
          <mobileIcon class="w-6 h-6 scale-75" :class="filterMobile ? 'text-logo group-hover:text-gray-500' : 'text-gray-500 group-hover:text-logo'"></mobileIcon>
          <div class="font-sm text-normal hidden lg:block">{{ $t('competitor.forms.mobile') }}</div>
        </div>
        <div @click="() => {console.log('test');filterFavorites = !filterFavorites}" class="cursor-pointer flex flex-row items-center gap-2 px-2 py-1 rounded border border-input-border hover:bg-gray-25 dark:hover:bg-gray-800 group">
          <svg class="w-6 h-6 scale-75" :class="filterFavorites ? 'text-love group-hover:text-gray-500' : 'text-gray-500 group-hover:text-love'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.645 20.9107L11.6384 20.9072L11.6158 20.8949C11.5965 20.8844 11.5689 20.8693 11.5336 20.8496C11.4629 20.8101 11.3612 20.7524 11.233 20.6769C10.9765 20.5261 10.6132 20.3039 10.1785 20.015C9.31074 19.4381 8.15122 18.5901 6.9886 17.5063C4.68781 15.3615 2.25 12.1751 2.25 8.25C2.25 5.32194 4.7136 3 7.6875 3C9.43638 3 11.0023 3.79909 12 5.0516C12.9977 3.79909 14.5636 3 16.3125 3C19.2864 3 21.75 5.32194 21.75 8.25C21.75 12.1751 19.3122 15.3615 17.0114 17.5063C15.8488 18.5901 14.6893 19.4381 13.8215 20.015C13.3868 20.3039 13.0235 20.5261 12.767 20.6769C12.6388 20.7524 12.5371 20.8101 12.4664 20.8496C12.4311 20.8693 12.4035 20.8844 12.3842 20.8949L12.3616 20.9072L12.355 20.9107L12.3523 20.9121C12.1323 21.0289 11.8677 21.0289 11.6477 20.9121L11.645 20.9107Z" fill="currentColor"></path></svg>
          <div class="font-sm text-normal hidden lg:block">{{ $t('competitor.forms.favorites') }}</div>
        </div>
      </div>
    </div>
    <div v-if="screenshotsFiltered.length" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl::grid-cols-6 gap-6">
      <screenshot-comp :competitor="competitor" :screenshot="screenshot" v-for="screenshot in screenshotsFiltered" :key="screenshot.id" @favorite="() => {screenshot.favorite = !screenshot.favorite}"/>
    </div>
    <div v-else class="flex justify-center items-center text-xl text-gray-600">
      {{ $t('competitor.tabs.website.screenshots.none') }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
