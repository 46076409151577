<script setup lang="ts">
import AddCompetitor from '@/components/competitors/AddCompetitorComp.vue'
import ModalComp from '@/components/basic/ModalComp.vue'
import { computed, reactive, ref, getCurrentInstance } from 'vue'
import { url, required, maxLength } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import { competitorType as addNewCompetitorType } from '@/interfaces/competitors'
import InfoComp from '@/components/basic/InfoComp.vue'
import { put } from '@/modules/asyncData'
import { competitorStore } from '@/stores/competitorStore'
import CompetitorDashboardComp from '@/components/competitors/CompetitorDashboardComp.vue'
import { authStore } from '@/stores/authStore'
import { useRouter } from 'vue-router'

const auth = authStore()
const competitors = competitorStore()

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const showModal = ref<boolean>(false)

const newCompetitor = reactive<addNewCompetitorType>({
  name: '',
  url: '',
  description: ''
})

const rules = computed(() => {
  return {
    name: { required },
    url: { required, url },
    description: { maxLength: maxLength(512) }
  }
})

const $v = useValidate(rules, newCompetitor)

const status = ref<string>('')
const router = useRouter()

const addNewCompetitor = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const r = await put<addNewCompetitorType>('competitor', newCompetitor)
      competitors.competitors.push(r.data)
      showModal.value = false
      newCompetitor.name = ''
      newCompetitor.url = ''
      newCompetitor.description = ''
      status.value = ''
      $v.value.$reset()
      $Progress.finish()
      await router.push('/competitor/' + r.data.id)
    } catch (e) {
      status.value = 'form.error.undefined'
      $Progress.fail()
    }
  }
}
</script>

<template>
  <div>
    <modal-comp v-if="showModal" @close-modal="showModal = false">
      <div class="flex flex-col w-full">
        <div class="text-xl font-bold mb-5 flex flex-row justify-center items-center">
          {{ $t('dashboard.forms.addNewTitle') }}
        </div>
        <info-comp class="error" :message="status" v-if="status === 'form.error.undefined'"/>
        <label>
          {{ $t('dashboard.forms.name') }}*
        </label>
        <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
        <input type="text" v-model="newCompetitor.name" :placeholder="$t('dashboard.forms.namePlaceholder')">
        <label>
          {{ $t('dashboard.forms.url') }}*
        </label>
        <info-comp class="error" :complex="$v.url.$errors[0]" v-if="$v.url.$error"/>
        <input type="text" v-model="newCompetitor.url" :placeholder="$t('dashboard.forms.urlPlaceholder')">
        <label>
          {{ $t('dashboard.forms.description') }}
        </label>
        <info-comp class="error" :complex="$v.description.$errors[0]" v-if="$v.description.$error"/>
        <input type="text" v-model="newCompetitor.description" :placeholder="$t('dashboard.forms.descriptionPlaceholder')">
        <div class="flex flex-row justify-evenly w-full mt-4">
          <button class="btn w-full" @click="addNewCompetitor()">{{ $t('dashboard.forms.addButton') }}</button>
        </div>
      </div>
    </modal-comp>
    <div class="flex flex-col px-10 py-5 w-full">
      <div class="text-4xl font-bold mt-5 mb-8">
        {{ $t('dashboard.subTitle') }}
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl::grid-cols-6 gap-6 flex-wrap">
        <CompetitorDashboardComp :competitor="competitor" v-for="competitor in competitors.competitors" :key="competitor.id" />
        <add-competitor @add-new-competitor="showModal = true" v-if="competitors.competitors.length < auth.resources.competitors"></add-competitor>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
