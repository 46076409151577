import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { configStore } from '@/stores/configStore'
import NavBarComp from '@/components/navigation/NavBarComp.vue'
import AuthView from '@/views/AuthView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import { i18n } from '@/modules/i18n'
import NotFoundView from '@/views/NotFoundView.vue'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
import { authStore } from '@/stores/authStore'
import emptyComp from '@/components/basic/emptyComp.vue'
import DashboardView from '@/views/DashboardView.vue'
import SettingsView from '@/views/SettingsView.vue'
import BillingView from '@/views/settings/BillingView.vue'
import PasswordView from '@/views/settings/PasswordView.vue'
import AccountView from '@/views/settings/AccountView.vue'
import CompetitorView from '@/views/CompetitorView.vue'
import WebsiteView from '@/views/competitor/WebsiteView.vue'
import NewsletterView from '@/views/competitor/NewsletterView.vue'
import SettingsCompetitorView from '@/views/competitor/SettingsCompetitorView.vue'
import TrialComp from '@/components/other/trialComp.vue'
import testView from '@/views/testView.vue'
import PricingView from '@/views/PricingView.vue'
import FeaturesView from '@/views/FeaturesView.vue'
import TermsView from '@/views/TermsView.vue'
import ShareView from '@/views/ShareView.vue'
import UIView from "@/views/settings/UIView.vue";
import AffiliateView from "@/views/AffiliateView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: HomeView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'home.title'
    }
  },
  {
    path: '/resources',
    name: 'resources',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: HomeView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'impressum.title'
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: PricingView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'pricing.title'
    }
  },
  {
    path: '/features',
    name: 'features',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: FeaturesView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'features.title'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: TermsView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'terms.title'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: PrivacyView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'privacy.title'
    }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: AffiliateView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'affiliate.title'
    }
  },
  {
    path: '/test',
    name: 'test',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: testView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      title: 'home.title'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      navBar: NavBarComp,
      trial: TrialComp,
      default: DashboardView,
      footer: emptyComp
    },
    meta: {
      auth: true,
      noAuth: false,
      title: 'dashboard.title'
    }
  },
  {
    path: '/competitor/:id',
    name: 'competitor',
    components: {
      navBar: NavBarComp,
      trial: TrialComp,
      default: CompetitorView,
      footer: emptyComp
    },
    props: true,
    children: [
      {
        path: '',
        alias: 'website',
        name: 'website',
        component: WebsiteView,
        meta: {
          auth: true,
          noAuth: false,
          title: 'competitor.tabs.website.title'
        }
      },
      {
        path: 'newsletter',
        name: 'newsletter',
        component: NewsletterView,
        meta: {
          auth: true,
          noAuth: false,
          title: 'competitor.tabs.newsletter.title'
        }
      },
      {
        path: 'settings',
        name: 'settingsCompetitor',
        component: SettingsCompetitorView,
        meta: {
          auth: true,
          noAuth: false,
          title: 'competitor.tabs.settings.title'
        }
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    components: {
      navBar: NavBarComp,
      trial: TrialComp,
      default: SettingsView,
      footer: emptyComp
    },
    children: [
      {
        path: 'account',
        alias: '',
        name: 'account',
        component: AccountView,
        meta: {
          auth: true,
          noAuth: false,
          title: 'settings.tabs.account.title'
        }
      },
      {
        path: 'password',
        name: 'password',
        component: PasswordView,
        meta: {
          auth: true,
          noAuth: false,
          title: 'settings.tabs.password.title'
        }
      },
      {
        path: 'billing/:success?',
        name: 'billing',
        component: BillingView,
        props: true,
        meta: {
          auth: true,
          noAuth: false,
          title: 'settings.tabs.billing.title'
        }
      },
      {
        path: 'ui',
        name: 'ui',
        component: UIView,
        props: true,
        meta: {
          auth: true,
          noAuth: false,
          title: 'settings.tabs.appearance.title'
        }
      }
    ],
    meta: {
      auth: true,
      noAuth: false,
      title: 'dashboard.title'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: AuthView,
      footer: emptyComp
    },
    children: [
      {
        path: 'login',
        alias: '',
        name: 'login',
        component: LoginView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.login.title'
        }
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.register.title'
        }
      },
      {
        path: 'forgot',
        name: 'forgotPassword',
        component: ForgotPasswordView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.forgot.title'
        }
      },
      {
        path: 'reset/:token',
        name: 'resetPassword',
        component: ResetPasswordView,
        props: true,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.reset.title'
        }
      }
    ]
  },
  {
    path: '/share/:hash',
    name: 'share',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: ShareView,
      footer: emptyComp
    },
    props: true,
    meta: {
      auth: false,
      noAuth: false,
      noDark: true,
      title: 'home.title'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components: {
      navBar: emptyComp,
      trial: emptyComp,
      default: NotFoundView,
      footer: emptyComp
    },
    meta: {
      auth: false,
      noAuth: false,
      title: 'notFound.title'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const { t, te } = i18n.global

  config.menu = false
  config.languageMenu = false
  try {
    // @ts-ignore
    document.title = to.meta.title && te(to.meta.title as string)
      ? t(to.meta.title as string) + ' | ' + config.projectTitle
      : config.projectTitle
  } catch (unused) {
    document.title = config.projectTitle
  }

  if (to.meta.noDark) {
    document.getElementsByTagName('html')[0].className = 'light'
  } else {
    document.getElementsByTagName('html')[0].className = config.darkModeSettings === 'system' ? (config.systemDarkMode ? 'dark' : 'light') : config.darkModeSettings
  }

  if (to.meta.noAuth || to.meta.auth) {
    await auth.checkJwt()
  }

  if (to.meta.auth && !auth.isAuth) {
    next({ name: 'login' })
  } else if (to.meta.noAuth && auth.isAuth) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  const auth = authStore()
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : (toDepth > fromDepth ? 'slide-left' : 'fade')
  if(to.query.a) {
    auth.affiliate = to.query.a as string
  }
})

export default router
