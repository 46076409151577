<script setup lang="ts">
import { ref } from 'vue'

const componentRef = ref()
const emits = defineEmits(['closeModal'])

</script>

<template>
  <div class="fixed right-0 top-0 w-full h-full flex justify-center items-center z-50" ref="componentRef">
    <div class="absolute w-full h-full backdrop-blur-sm backdrop-brightness-75" @click="emits('closeModal')"></div>
    <div class="flex justify-center items-center max-w-[600px] w-full min-h-50 m-10 md:m-0 pt-8 pb-10 px-16 z-10 bg-white dark:bg-gray-1000 rounded-xl border border-gray-50 mb-5 max-h-[90%]">
      <slot/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
