<script setup lang="ts">

import TabsComp from '@/components/navigation/tabsComp.vue'
import { tabs as tabsType } from '@/interfaces/basic'
import { getCurrentInstance, ref } from 'vue'
import { authStore } from '@/stores/authStore'
import { get, put } from '@/modules/asyncData'
import { configStore } from '@/stores/configStore'
import InfoComp from '@/components/basic/InfoComp.vue'

const props = defineProps({
  success: {
    type: String,
    required: false
  }
})

const auth = authStore()

const tabs: tabsType[] = [
  { id: 'monthly', title: 'settings.tabs.billing.monthly' },
  { id: 'annually', title: 'settings.tabs.billing.annually' }
]

const currentTab = ref<'monthly' | 'annually'>('monthly')

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const openStripe = async () => {
  try {
    $Progress.start()
    const link = await get<string>('stripe/dashboard')
    location.href = link.data
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
  }
}

const config = configStore()
if (!config.prices.plus.monthly.price) config.loadPrices()

const pay = async (priceId?: string) => {
  try {
    $Progress.start()
    location.href = (await put<string>('stripe/checkout', { priceId })).data
    $Progress.finish()
  } catch (e) {
    console.log(e)
    $Progress.fail()
  }
}

if (props.success) {
  setTimeout(() => { auth.checkJwt(true) }, 5000)
}

</script>

<template>
  <div class="flex flex-col w-full">
    <div class="text-lg font-bold mb-2.5">{{ $t('settings.tabs.billing.subTitle') }}</div>
    <info-comp class="success" message="settings.tabs.billing.success"  v-if="success"/>
    <tabs-comp :current-tab="currentTab" @set-tab="(newTab) => { currentTab = newTab }" :tabs="tabs" :pre-fix="false"></tabs-comp>
    <div class="pt-10 pb-16 grid gap-[60px] grid-cols-1 lg:grid-cols-2">
      <div class="flex flex-col px-5 rounded-lg pb-10 hover:dark:bg-gray-900 hover:bg-gray-10"
           :class="{'bg-gray-10 dark:bg-gray-900': auth.user.plan === 'plus'}">
        <div class="pt-5 pb-10">
          <div class="text-3xl font-bold my-2.5">
            {{  $t('settings.tabs.billing.plus.header') }}
          </div>
          <div class="text-sm font-normal mb-7.5 leading-8 dark:text-gray-400">
            {{  $t('settings.tabs.billing.plus.subTitle') }}
          </div>
          <div class="text-4xl font-bold my-2.5">
            ${{ currentTab === 'monthly' ? config.prices.plus.monthly.price : config.prices.plus.annually.price }}
          </div>
          <div class="text-sm font-normal leading-8 mb-6">
            {{  currentTab === 'monthly' ? $t('settings.tabs.billing.billingSchedule.monthly') : $t('settings.tabs.billing.billingSchedule.annually') }}
          </div>
          <div class="btn w-[200px] text-sm font-medium" v-if="auth.user.plan !== 'plus'" @click="pay(currentTab === 'monthly' ? config.prices.plus.monthly.id : config.prices.plus.annually.id)">
            {{ $t('settings.tabs.billing.getStarted') }}
          </div>
          <div v-else class="btn secondary disabled w-[200px] text-sm font-medium">
            {{ $t('settings.tabs.billing.currentPlan') }}
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-bold text-base">
            {{  $t('settings.tabs.billing.plus.includes') }}
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.plus.list.1') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.plus.list.2') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.plus.list.3') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.plus.list.4') }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col px-5 rounded-lg pb-10 hover:dark:bg-gray-900 hover:bg-gray-10"
      :class="{'bg-gray-10 dark:bg-gray-900': auth.user.plan === 'pro'}">
        <div class="pt-5 pb-10">
          <div class="text-3xl font-bold my-2.5">
            {{  $t('settings.tabs.billing.pro.header') }}
          </div>
          <div class="text-sm font-normal mb-7.5 leading-8 dark:text-gray-400">
            {{  $t('settings.tabs.billing.pro.subTitle') }}
          </div>
          <div class="text-4xl font-bold my-2.5">
            ${{ currentTab === 'monthly' ? config.prices.pro.monthly.price : config.prices.pro.annually.price }}
          </div>
          <div class="text-sm font-normal leading-8 mb-6">
            {{  currentTab === 'monthly' ? $t('settings.tabs.billing.billingSchedule.monthly') : $t('settings.tabs.billing.billingSchedule.annually') }}
          </div>
          <div class="btn w-[200px] text-sm font-medium" v-if="auth.user.plan !== 'pro'" @click="pay(currentTab === 'monthly' ? config.prices.pro.monthly.id : config.prices.pro.annually.id)">
            {{ $t('settings.tabs.billing.getStarted') }}
          </div>
          <div v-else class="btn secondary disabled w-[200px] text-sm font-medium">
            {{ $t('settings.tabs.billing.currentPlan') }}
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-bold text-base">
            {{  $t('settings.tabs.billing.pro.includes') }}
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.pro.list.1') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.pro.list.2') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class="dark:text-gray-400">
              {{  $t('settings.tabs.billing.pro.list.3') }}
            </div>
          </div>
          <div class="flex flex-row gap-3 items-center">
            <img src="/images/icons/checkMark.svg" alt="checkMark">
            <div class=" dark:text-gray-400">
              {{  $t('settings.tabs.billing.pro.list.4') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-lg bg-stripe-billing text-font-normal font-normal text-sm px-6 py-3.5">
      {{ $t('settings.tabs.billing.stripeText') }}
      <span class="ml-2 font-bold underline cursor-pointer" @click="openStripe()">{{ $t('settings.tabs.billing.openStripe') }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
