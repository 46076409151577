<script setup lang="ts">
import useValidate from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { reactive, ref, computed, toRefs, getCurrentInstance } from 'vue'
import { authStore } from '@/stores/authStore'
import InfoComp from '@/components/basic/InfoComp.vue'
import { useRouter } from 'vue-router'
import PasswordComp from '@/components/forms/PasswordComp.vue'

const props = defineProps<{
  token: string
}>()

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const { token } = toRefs(props)
const auth = authStore()

const router = useRouter()

const user = reactive({
  password: '',
  password2: ''
})

const status = ref<string>('')

const reset = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    $Progress.finish()
    auth.resetPassword(user.password, token.value)
      .then(async () => {
        $Progress.finish()
        status.value = ''
        await router.push({ name: 'dashboard' })
      })
      .catch((e) => {
        status.value = e?.response?.status === 401 ? 'auth.reset.invalid' : 'auth.reset.error'
        $Progress.fail()
      })
  }
}

const rules = computed(() => {
  return {
    password: { required, minLength: minLength(8) },
    password2: { required, sameAs: sameAs(user.password) }
  }
})

const $v = useValidate(rules, user)

</script>

<template>
  <div class="flex justify-center items-center mb-5 text-xl font-bold">
    {{ $t('auth.reset.title') }}
  </div>
  <info-comp class="my-4" :class="status === 'auth.registration.success' ? 'success' : 'error'" v-if="status" :message="status"/>

  <div class="flex flex-col w-full mb-4">

    <label>
      {{ $t('form.newPassword') }}
    </label>
    <info-comp class="error" :complex="$v.password.$errors[0]" v-if="$v.password.$error"/>
    <password-comp @typed="typed => {user.password = typed}" />
    <label>
      {{ $t('form.password2') }}
    </label>
    <info-comp class="error" :complex="$v.password2.$errors[0]" v-if="$v.password2.$error"/>
    <password-comp @enter="reset" @typed="typed => {user.password2 = typed}" />
    <div class="flex flex-row justify-evenly w-full mt-4">
      <button class="btn w-full" @click="reset">{{ $t('auth.reset.button') }}</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
