<script setup lang="ts">
import { PropType } from 'vue'
import { tabs as tabsType } from '@/interfaces/basic'

const props = defineProps({
  tabs: Array as PropType<Array<tabsType>>,
  currentTab: String,
  preFix: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['setTab'])
</script>

<template>
  <div class="flex flex-row w-full border-b border-gray-50 dark:border-gray-800 text-sm">
    <div class="w-10" v-if="preFix"></div>
    <div v-for="tab in tabs" :key="tab.id" class="flex flex-row">
      <div class="py-3 font-bold hover:border-secondary-500 hover:border-b-2 dark:hover:border-gray-700"
           :class="tab.id === currentTab || tab.parent === currentTab ? 'font-bold dark:text-white border-secondary-500 border-b-2 dark:border-gray-700' : 'font-medium text-gray-600 dark:text-gray-200' "
           @click="emit('setTab', tab.id)"
      >
        {{ $t(tab.title) }}
      </div>
      <div class="w-10"></div>
    </div>
    <div class="flex-grow"></div>
  </div>
</template>

<style scoped lang="scss">

</style>
