<script setup lang="ts">
import { competitorType } from '@/interfaces/competitors'
import pendingIcon from '@material-design-icons/svg/round/pending.svg'
import { configStore } from '@/stores/configStore'
interface propType {
  competitor: competitorType
}
const props = defineProps<propType>()
const config = configStore()
</script>

<template>
  <component
    :is="competitor.frozen ? 'span' : 'router-link'"
    class="flex flex-row gap-3 items-center px-2.5 py-2 rounded-lg"
    :class="competitor.frozen ? 'opacity-25' : 'hover:bg-gray-25 dark:hover:bg-gray-800'"
    :to="{name: 'website', params: {id: competitor.id}}"
  >
    <img class="w-5 h-5" alt="logo" :src="config.dataURL +'data/logos/' + competitor.logo + '.png'" v-if="competitor.logo"/>
    <pendingIcon class="w-6 h-6 text-logo" v-else/>
    <div class="text-base font-bold text-black dark:text-white">{{ competitor.name }}</div>
  </component>
</template>

<style scoped lang="scss">

</style>
