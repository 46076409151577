<script setup lang="ts">

</script>

<template>
  <div class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
    <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
      <div class="relative">
        <div class="absolute">
          <div class="">
            <h1 class="my-2 font-bold text-2xl">
              {{ $t('notFound.header') }}
            </h1>
            <p class="my-2 ">
              {{ $t('notFound.description') }}
            </p>
            <router-link to="/dashboard" class="btn">
              {{ $t('notFound.button') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="text-[14rem] font-extrabold">
      404
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
