<script setup lang="ts">
import InfoComp from '@/components/basic/InfoComp.vue'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { email, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import PasswordComp from '@/components/forms/PasswordComp.vue'
import { authStore } from '@/stores/authStore'

const status = ref<string>('')

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const auth = authStore()

const user = reactive({
  currentPassword: '',
  email: auth.user.email,
  name: auth.user.name
})

const rules = computed(() => {
  return {
    currentPassword: { required },
    email: { required, email },
    name: { required }
  }
})

const $v = useValidate(rules, user)

const changeAccount = async (): Promise<void> => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    $Progress.start()
    auth.updateAccount(user).then(() => {
      status.value = 'settings.tabs.account.success'
      $Progress.finish()
    })
      .catch(unused => {
        $Progress.fail()
        status.value = 'settings.tabs.account.error'
      })
  }
}

</script>

<template>
  <div class="flex flex-col w-full">
    <div class="text-lg font-bold mb-2.5">{{ $t('settings.tabs.account.subTitle') }}</div>
    <info-comp class="my-4" :class="status === 'settings.tabs.account.error' ? 'error' : 'success'" v-if="status" :message="status"/>
    <label>
      {{ $t('form.currentPassword') }}*
    </label>
    <info-comp class="error" :complex="$v.currentPassword.$errors[0]" v-if="$v.currentPassword.$error"/>
    <password-comp @typed="typed => {user.currentPassword = typed}" />
    <label>
      {{ $t('form.email') }}*
    </label>
    <info-comp class="error" :complex="$v.email.$errors[0]" v-if="$v.email.$error"/>
    <input type="text" v-model="user.email">
    <label>
      {{ $t('form.name') }}*
    </label>
    <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
    <input type="text" v-model="user.name">
    <div class="flex flex-row justify-start w-full mt-4">
      <button class="btn" @click="changeAccount()">{{ $t('form.saveChanges') }}</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
