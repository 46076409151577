<script setup lang="ts">
import { configStore } from '@/stores/configStore'

const config = configStore()

</script>

<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex items-center justify-center">
      <div class="w-[550px]">
        <div class="relative p-8 flex justify-center items-center flex-col py-10 px-7.5">
          <router-link to="/">
            <img :src="'/images/logo/logo' + (config.darkMode === 'dark' ? '-white' : '') + '.png'" alt="logo" class="h-9 mb-10">
          </router-link>
          <RouterView class="p-4"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active{
  transition: all 1s ease-out;
}

.slide-fade-enter-from{
  transform: translateX(-20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
