<script setup lang="ts">
import { userRegister } from '@/interfaces/auth'
import { authStore } from '@/stores/authStore'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { required, email } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import InfoComp from '@/components/basic/InfoComp.vue'
import { configStore } from '@/stores/configStore'
import DropDownComp from '@/components/forms/DropDownComp.vue'
import { dropDown } from '@/interfaces/basic'

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const status = ref<string>('')
const step = ref<number>(1)

const auth = authStore()
const config = configStore()
const user = reactive<userRegister>({
  email: '',
  name: '',
  company: undefined,
  role: undefined,
  hearFirst: undefined,
  language: undefined
})

const rules = computed(() => {
  return {
    email: { required, email },
    name: { required }
  }
})

const $v = useValidate(rules, user)

const nextStep = async (): Promise<void> => {
  await $v.value.$validate()

  if (!$v.value.$error) {
    step.value = 2
  }
}
const register = async (): Promise<void> => {
  $Progress.start()
  user.language = config.currentLanguage.code
  auth.register(user).then(() => {
    $Progress.finish()
    status.value = 'auth.registration.success'
  })
    .catch(e => {
      $Progress.fail()
      status.value = 'auth.registration.error'
    })
}

const roles: dropDown[] = [
  { value: 'founder', title: 'auth.registration.roles.founder' },
  { value: 'ceo', title: 'auth.registration.roles.ceo' },
  { value: 'marketing', title: 'auth.registration.roles.marketing' },
  { value: 'designer', title: 'auth.registration.roles.designer' },
  { value: 'productManager', title: 'auth.registration.roles.productManager' },
  { value: 'other', title: 'auth.registration.roles.other' }
]

const hearFirst: dropDown[] = [
  { value: 'advertisement', title: 'auth.registration.hearFirst.advertisement' },
  { value: 'searchEngine', title: 'auth.registration.hearFirst.searchEngine' },
  { value: 'referred', title: 'auth.registration.hearFirst.referred' },
  { value: 'blog', title: 'auth.registration.hearFirst.blog' },
  { value: 'socialMedia', title: 'auth.registration.hearFirst.socialMedia' },
  { value: 'other', title: 'auth.registration.hearFirst.other' }
]

const resended = ref<boolean>(false)

const resend = () => {
  $Progress.start()
  user.language = config.currentLanguage.code
  auth.resendEmail(user.email)
    .then(() => {
      $Progress.finish()
      resended.value = true
    })
    .catch(e => {
      $Progress.fail()
    })
}

</script>

<template>
  <info-comp class="my-4 error" v-if="status && status !== 'auth.registration.success'" :message="status"/>
  <div class="flex flex-col w-full mb-4" v-if="step === 1 && status !== 'auth.registration.success'">
    <button class="btn secondary mb-1.5" @click="$router.push({name: 'login'})">{{ $t('auth.login.loginNow') }}</button>
    <div class="flex flex-row justify-evenly w-full py-6">
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
      <div class="px-8 uppercase font-normal text-gray-300 text-sm">{{ $t('basic.or')}}</div>
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
    </div>
    <div class="flex justify-center items-center mb-5 text-xl font-bold">
      {{ $t('auth.registration.registerForFree') }}
    </div>
    <label>
      {{ $t('form.email') }}
    </label>
    <info-comp class="error" :complex="$v.email.$errors[0]" v-if="$v.email.$error"/>
    <input type="text" v-model="user.email">
    <label>
      {{ $t('form.name') }}
    </label>
    <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
    <input type="text" v-model="user.name" @keyup.enter="nextStep()">
    <div class="flex flex-row justify-evenly w-full mt-4">
      <button class="btn w-full" @click="nextStep()">{{ $t('auth.registration.getStarted') }}</button>
    </div>
  </div>
  <div class="flex flex-col w-full mb-4" v-else-if="status !== 'auth.registration.success'">
    <div class="flex flex-col items-center justify-center w-full pb-5">
      <div class="text-xl font-bold">{{ $t('auth.registration.getStartedText')}}</div>
      <div class="text-sm font-normal text-gray-200">{{ $t('auth.registration.freeTrial')}}</div>
    </div>
    <label>
      {{ $t('form.company') }}
    </label>
    <input type="text" v-model="user.company">
    <label>
      {{ $t('form.role') }}
    </label>
    <drop-down-comp :items="roles" @selected="(selected) => user.role = selected"/>
    <label>
      {{ $t('form.hearFirst') }}
    </label>
    <drop-down-comp :items="hearFirst" @selected="(selected) => user.hearFirst = selected"/>
    <div class="flex w-full">
      <button class="btn w-full" @click="register">{{ $t('auth.registration.getStarted') }}</button>
    </div>
    <div class="text-sm font-normal text-gray-200 mt-5 text-center">{{ $t('auth.registration.acceptTerms')}}</div>
  </div>
  <div v-else class="flex flex-col justify-center items-center text-black dark:text-white">
    <img src="/images/icons/emailVerify.jpeg" alt="emailVerify" class="h-[180px] mb-5">
    <div class="text-3xl mb-1">{{ $t('auth.registration.verify.header') }}</div>
    <div class="text-sm ">{{ $t('auth.registration.verify.text1') }}</div>
    <div class="btn secondary mt-4" v-if="!resended" @click="resend">{{ $t('auth.registration.verify.button') }}</div>
    <div v-else class="text-sm font-bold mt-4">{{ $t('auth.registration.verify.done') }}</div>
  </div>
</template>

<style scoped lang="scss">

</style>
