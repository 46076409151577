<script setup lang="ts">
import { authStore } from '@/stores/authStore'
// import LanguageSelect from '@/components/basic/LanguageSelect.vue'
// import DarkModeSelect from '@/components/basic/DarkModeSelect.vue'

import logoutIcon from '@material-design-icons/svg/round/logout.svg'
import settingsIcon from '@material-design-icons/svg/round/settings.svg'
import { competitorStore } from '@/stores/competitorStore'
import { useRouter } from 'vue-router'
import { configStore } from '@/stores/configStore'
import menuIcon from '@material-design-icons/svg/round/menu.svg'
import closeIcon from '@material-design-icons/svg/round/close.svg'
import NavbarCompetitorComp from '@/components/competitors/NavbarCompetitorComp.vue'

const auth = authStore()
const config = configStore()
const competitor = competitorStore()
const router = useRouter()

const logout = async () => {
  try {
    await auth.logout()
  } catch (e) {
    console.log('logout failed', e)
  }
}
</script>

<template>
  <div class="absolute top-0 z-50 md:hidden p-4" v-if="!config.menu">
    <menuIcon class="w-10 h-10" @click="config.menu = true"/>
  </div>
  <div
    class="z-50 w-[300px] min-w-[300px] px-5 pb-7.5 border-r border-gray-50 dark:border-gray-800 transition-all duration-100 absolute md:relative md:ml-0 bg-white dark:bg-gray-1000 h-full overflow-hidden"
    :class="{'ml-[-300px]': !config.menu}"
    ref="componentRef"
  >
    <div class="flex flex-row justify-end absolute top-0 right-4 md:hidden p-4 pl-0">
      <closeIcon class="w-5 h-5" @click="config.menu = false"/>
    </div>
    <div class="py-10 flex flex-col justify-between h-full gap-4">
      <div class="mb-4 flex-grow-0">
        <router-link :to="{name: 'dashboard'}" class="flex justify-center items-align mb-8">
          <img :src="'/images/logo/logo' + (config.darkMode === 'dark' ? '-white' : '') + '.png'" alt="logo" class="h-11">
        </router-link>
<!--        <div class="flex flex-row justify-end">-->
<!--          <div class="flex relative h-8 w-8 mr-4">-->
<!--            <LanguageSelect />-->
<!--          </div>-->
<!--          <div class="flex relative h-8 w-8">-->
<!--            <DarkModeSelect />-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="h-full flex flex-col text-gray-600 dark:text-gray-200 text-sm flex-grow overflow-hidden">
        <div class="flex flex-col gap-1.5 flex-grow-0">
          <router-link :to="{name: 'dashboard'}" class="flex flex-row gap-2 p-2.5 rounded-lg hover:bg-gray-25 items-center dark:hover:bg-gray-800">
            <svg class="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.4697 3.84099C11.7626 3.5481 12.2374 3.5481 12.5303 3.84099L21.2197 12.5303C21.5126 12.8232 21.9874 12.8232 22.2803 12.5303C22.5732 12.2374 22.5732 11.7626 22.2803 11.4697L13.591 2.78033C12.7123 1.90165 11.2877 1.90165 10.409 2.78033L1.71967 11.4697C1.42678 11.7626 1.42678 12.2374 1.71967 12.5303C2.01256 12.8232 2.48744 12.8232 2.78033 12.5303L11.4697 3.84099Z" fill="currentColor"></path><path d="M12 5.43198L20.159 13.591C20.1887 13.6207 20.2191 13.6494 20.25 13.6771V19.875C20.25 20.9105 19.4105 21.75 18.375 21.75H15C14.5858 21.75 14.25 21.4142 14.25 21V16.5C14.25 16.0858 13.9142 15.75 13.5 15.75H10.5C10.0858 15.75 9.75 16.0858 9.75 16.5V21C9.75 21.4142 9.41421 21.75 9 21.75H5.625C4.58947 21.75 3.75 20.9105 3.75 19.875V13.6771C3.78093 13.6494 3.81127 13.6207 3.84099 13.591L12 5.43198Z" fill="currentColor"></path>
            </svg>
            <div class="font-medium">{{ $t('dashboard.title') }}</div>
          </router-link>
          <router-link :to="{name: 'account'}" class="flex flex-row gap-2 p-2.5 rounded-lg hover:bg-gray-25 items-center dark:hover:bg-gray-800">
            <settingsIcon class="h-6 w-6 text-gray-600"/>
            <div class="font-medium">{{ $t('settings.title') }}</div>
          </router-link>
        </div>
        <div class="flex flex-col mt-6 gap-4.5 flex-grow overflow-hidden relative">
          <div class="p-2.5 text-gray-600 dark:text-gray-200 font-normal">{{ $t('basic.competitors') }}</div>
          <div v-if="competitor.competitors.length" class="flex flex-col overflow-hidden relative">
            <div class="bg-gradient-to-b from-white dark:from-gray-1000 to-[8px] absolute h-full w-full pointer-events-none"></div>
            <div class="bg-gradient-to-t from-white dark:from-gray-1000 to-[8px] absolute h-full w-full pointer-events-none"></div>
            <div class="flex flex-col overflow-y-auto gap-1 py-2 scrollbar-hide">
              <NavbarCompetitorComp :competitor="competitorItem" v-for="competitorItem in competitor.competitors" :key="competitorItem.id"></NavbarCompetitorComp>
            </div>
           </div>
          <div v-else class="font-bold p-2.5">
            {{ $t('basic.noCompetitors') }}
          </div>
        </div>
      </div>
      <div class="flex flex-col mb-4 gap-1.5 flex-grow-0">
<!--        <a href="mailto:info@webrivals.co?subject=Feedback" class="btn transparent w-full">-->
<!--          {{ $t('navbar.feedback') }}-->
<!--        </a>-->
        <div class="flex flex-row p-2.5 hover:bg-gray-25 rounded-xl items-center dark:hover:bg-gray-800 cursor-pointer" @click="logout">
          <logoutIcon class="h-6 w-6 text-gray-600 dark:text-gray-200"/>
          <div class="ml-2 text-sm font-medium text-gray-600 dark:text-gray-200">{{ $t('navbar.logout') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
