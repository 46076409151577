<script setup lang="ts">
import TabsComp from '@/components/navigation/tabsComp.vue'
import { tabs as tabsType } from '@/interfaces/basic'

import { ref } from 'vue'

const tabs: tabsType[] = [
  { id: 'account', title: 'settings.tabs.account.title', parent: 'settings' },
  { id: 'password', title: 'settings.tabs.password.title' },
  { id: 'billing', title: 'settings.tabs.billing.title' },
  { id: 'ui', title: 'settings.tabs.ui.title' }
]
</script>

<template>
  <div class="flex flex-col items-start justify-start">
    <div class="text-3xl px-10 pt-10 pb-5 font-bold">{{ $t('settings.title') }}</div>
    <tabs-comp :tabs="tabs" :current-tab="$router.currentRoute.value.name" @set-tab="(newTab) => {$router.push({name: newTab})}"></tabs-comp>
    <RouterView class="p-10 pt-5"/>
  </div>
</template>

<style scoped lang="scss">

</style>
