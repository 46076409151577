<script setup lang="ts">
import TabsComp from '@/components/navigation/tabsComp.vue'
import { tabs as tabsType } from '@/interfaces/basic'
import router from '@/router'
import { competitorType, routineNewsletterType, routineType } from '@/interfaces/competitors'
import { ref, computed, reactive, watch, getCurrentInstance } from 'vue'
import { get } from '@/modules/asyncData'
import { competitorStore } from '@/stores/competitorStore'
import { configStore } from '@/stores/configStore'

const competitors = competitorStore()

const tabs: tabsType[] = [
  { id: 'website', title: 'competitor.tabs.website.title', parent: 'competitor' },
  { id: 'newsletter', title: 'competitor.tabs.newsletter.title' },
  { id: 'settingsCompetitor', title: 'competitor.tabs.settings.title' }
]

const props = defineProps(
  {
    id: {
      type: String,
      required: true
    }
  }
)
await competitors.loadAll()
const competitor = computed(() => competitors.competitors.find(competitor => competitor.id === props.id))
const routines: routineType[] = reactive([])
const newsletter: routineNewsletterType[] = reactive([])

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const loadDetails = async () => {
  try {
    $Progress.start()
    const tempDetails = (await get<competitorType>('competitor', { id: props.id })).data
    if (tempDetails.routines) {
      routines.splice(0)
      routines.push(...tempDetails.routines)
    }
    if (tempDetails.newsletter) {
      newsletter.splice(0)
      newsletter.push(...tempDetails.newsletter)
    }
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
    await router.push({ name: 'dashboard' })
  }
}

await loadDetails()

if (!competitor.value) await router.push({ name: 'dashboard' })

const config = configStore()

</script>

<template>
  <div class="flex flex-col items-start justify-start" v-if="competitor">
    <div class="px-10 pt-10 flex flex-row gap-2 justify-center items-center">
      <img class="w-6 h-6" alt="logo" :src="config.dataURL +'data/logos/' + competitor.logo + '.png'" v-if="competitor.logo"/>
      <div class="text-3xl font-bold">{{ competitor.name }}</div>
    </div>
    <div class="text-sm px-10 pb-5">{{ competitor.url }}</div>
    <tabs-comp :tabs="tabs" :current-tab="$router.currentRoute.value.name" @set-tab="(newTab) => {$router.push({name: newTab})}"></tabs-comp>
    <RouterView class="pt-5 w-full"
                :routines="routines"
                :newsletter="newsletter"
                :competitor="competitors.competitors.find(competitor => competitor.id === id)"
                @update-routines="(newRoutines) => {
                  routines.splice(0)
                  routines.push(...newRoutines)
                }"
                @update-newsletter="(newNewsletters) => {
                  newsletter.splice(0)
                  newsletter.push(...newNewsletters)
                }"
    />
  </div>
</template>

<style scoped lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active{
  transition: all 1s ease-out;
}

.slide-fade-enter-from{
  transform: translateX(-20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
