<script setup lang="ts">

</script>

<template>
  <section class="footer">
    <div class="footer-inner">
      <div class="w-layout-grid footer-upper">
        <div id="w-node-dd257cca-3bb9-166f-296e-4cdc807bde23-807bde20" class="footer-grid"><img src="images/webrivals-brand-color.png" loading="lazy" sizes="188.3125px" srcset="images/webrivals-brand-color-p-500.png 500w, images/webrivals-brand-color-p-800.png 800w, images/webrivals-brand-color-p-1080.png 1080w, images/webrivals-brand-color.png 1177w" alt="" class="image-8">
          <div class="dfgf">Fast &amp; easy to automatically monitor every action of your competitors<br></div>
        </div>
        <div id="w-node-dd257cca-3bb9-166f-296e-4cdc807bde28-807bde20" class="footer-grid">
          <div class="footer-info">Resources<br></div>
          <a href="#" class="footer-cta w-button">Why Competition Monitoring?</a>
          <a href="#" class="footer-cta w-button">Competitor Analysis Market Map</a>
          <a href="#" class="footer-cta w-button">Competitor Benchmarking Templates</a>
        </div>
        <div id="w-node-dd257cca-3bb9-166f-296e-4cdc807bde32-807bde20" class="footer-grid">
          <div class="footer-info">Resources<br></div>
          <a href="#" class="footer-cta w-button">Competition Monitoring Best Practices</a>
          <a href="#" class="footer-cta w-button">Social Media Competitive Analysis</a>
          <a href="#" class="footer-cta w-button">Competitor SWOT Template</a>
        </div>
        <div id="w-node-dd257cca-3bb9-166f-296e-4cdc807bde3c-807bde20" class="footer-grid">
          <div class="footer-info">Product<br></div>
          <router-link :to="{name: 'features'}" class="footer-cta w-button">Features</router-link>
          <router-link :to="{name: 'pricing'}" class="footer-cta w-button">Pricing</router-link>
          <router-link :to="{name: 'affiliate'}" class="footer-cta w-button">Affiliate Program</router-link>
          <a href="mailto:info@webrivals.co" class="footer-cta w-button">Contact</a>
        </div>
      </div>
      <div class="footer-lower">
        <div class="footer-brand">© WebRivals<br></div>
        <router-link :to="{name: 'terms'}" class="footer-lower-cta w-button">Terms of Service</router-link>
        <router-link :to="{name: 'privacy'}" class="footer-lower-cta w-button">Privacy Policy</router-link>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
