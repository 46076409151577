<script setup lang="ts">
import { routineType } from '@/interfaces/competitors'
import InfoComp from '@/components/basic/InfoComp.vue'
import DropDownComp from '@/components/forms/DropDownComp.vue'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { helpers, required, requiredIf, url } from '@vuelidate/validators'
import { del, patch } from '@/modules/asyncData'
import useValidate from '@vuelidate/core'
import { dropDown } from '@/interfaces/basic'
import { phones } from '@/constants/phones'
import ModalComp from '@/components/basic/ModalComp.vue'

interface propsType {
  routine: routineType
}
const props = defineProps<propsType>()

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const emits = defineEmits(['changed', 'status', 'deleteRoutine'])

const copyRoutine = reactive<routineType>(JSON.parse(JSON.stringify(props.routine)))

const rules = computed(() => {
  return {
    name: { required },
    path: { required, url },
    schedule: { required },
    mobile: {
      required: helpers.withParams(
        { type: 'device' },
        () => copyRoutine.mobile || copyRoutine.desktop)
    },
    desktop: {},
    emulate: { required: requiredIf(() => copyRoutine.mobile) }
  }
})

const $v = useValidate(rules, copyRoutine)

const dropDownPhone: dropDown[] = []

phones.forEach((phone: string) => { dropDownPhone.push({ value: phone, title: phone }) })

const status = ref<string>('')
const editRoutine = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const routines = (await patch<routineType>('routine', copyRoutine)).data
      emits('changed', routines)
      $Progress.finish()
    } catch (e) {
      if (e.response && e.response.status === 406) {
        status.value = 'competitor.forms.differentHost'
      }
      $Progress.fail()
    }
  }
}

const statusChange = async () => {
  try {
    $Progress.start()
    const routines = (await patch('routine/status', { id: copyRoutine.id, active: !copyRoutine.active })).data
    copyRoutine.active = !copyRoutine.active
    emits('status', routines)
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
  }
}

const delRoutine = async () => {
  try {
    $Progress.start()
    const routines = (await del('routine', { id: copyRoutine.id })).data
    emits('deleteRoutine', routines)
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
  }
}
const showDeleteModal = ref<boolean>(false)
</script>

<template>
  <div class="w-full">
    <modal-comp v-if="showDeleteModal" @close-modal="showDeleteModal = false">
      <div class="flex flex-col gap-4">
        <div class="text-base font-semibold">{{ $t('form.areYouSure') }}</div>
        <div class="flex flex-row justify-around">
          <div class="btn secondary hover:scale-105" @click="showDeleteModal = false;">{{ $t('basic.cancel') }}</div>
          <div class="btn hover:scale-105" @click="delRoutine()">{{ $t('basic.yes') }}</div>
        </div>
      </div>
    </modal-comp>
    <div class="flex flex-col w-full">
      <div class="text-xl font-bold mb-5 flex flex-row justify-center items-center">
        <span class="mr-2 text-lg" :class="copyRoutine.active && !copyRoutine.frozen ? 'text-routine-play' : 'text-routine-pause'">&#x2022;</span>{{ $t('competitor.forms.editRoutine') }}
      </div>
      <div class="flex flex-row justify-center items-center mb-5 gap-4">
        <div class="p-1.5 bg-routine-playBackground rounded-lg hover:scale-110" @click="statusChange()" v-if="!copyRoutine.active">
          <svg class="w-6 h-6 text-routine-play" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5.65257C4.5 4.22644 6.029 3.32239 7.2786 4.00967L18.8192 10.357C20.1144 11.0694 20.1144 12.9304 18.8192 13.6428L7.2786 19.9901C6.029 20.6774 4.5 19.7733 4.5 18.3472V5.65257Z" fill="currentColor"></path></svg>
        </div>
        <div class="p-1.5 bg-routine-pauseBackground rounded-lg hover:scale-110" @click="statusChange()" v-else>
          <svg class="w-6 h-6 text-routine-pause" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 5.25C6.75 4.83579 7.08579 4.5 7.5 4.5H9C9.41421 4.5 9.75 4.83579 9.75 5.25V18.75C9.75 19.1642 9.41421 19.5 9 19.5H7.5C7.30109 19.5 7.11032 19.421 6.96967 19.2803C6.82902 19.1397 6.75 18.9489 6.75 18.75L6.75 5.25ZM14.25 5.25C14.25 4.83579 14.5858 4.5 15 4.5H16.5C16.6989 4.5 16.8897 4.57902 17.0303 4.71967C17.171 4.86032 17.25 5.05109 17.25 5.25V18.75C17.25 19.1642 16.9142 19.5 16.5 19.5H15C14.5858 19.5 14.25 19.1642 14.25 18.75V5.25Z" fill="currentColor"></path></svg>
        </div>
        <div class="p-1.5 bg-routine-deleteBackground rounded-lg hover:scale-110" @click="showDeleteModal = true">
          <svg class="w-6 h-6 text-routine-delete" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5001 4.47819V4.70498C17.4548 4.79237 18.4017 4.90731 19.3398 5.04898C19.6871 5.10143 20.0332 5.15755 20.3781 5.2173C20.7863 5.28799 21.0598 5.67617 20.9891 6.0843C20.9184 6.49244 20.5302 6.76598 20.1221 6.69529C20.0525 6.68323 19.9829 6.67132 19.9131 6.65957L18.9077 19.7301C18.7875 21.2931 17.4842 22.5 15.9166 22.5H8.08369C6.51608 22.5 5.21276 21.2931 5.09253 19.7301L4.0871 6.65957C4.0174 6.67132 3.94774 6.68323 3.87813 6.69529C3.47 6.76598 3.08183 6.49244 3.01113 6.0843C2.94043 5.67617 3.21398 5.28799 3.62211 5.2173C3.96701 5.15755 4.31315 5.10143 4.66048 5.04898C5.59858 4.90731 6.5454 4.79237 7.50012 4.70498V4.47819C7.50012 2.91371 8.71265 1.57818 10.3156 1.52691C10.8749 1.50901 11.4365 1.5 12.0001 1.5C12.5638 1.5 13.1253 1.50901 13.6847 1.52691C15.2876 1.57818 16.5001 2.91371 16.5001 4.47819ZM10.3635 3.02614C10.9069 3.00876 11.4525 3 12.0001 3C12.5478 3 13.0934 3.00876 13.6367 3.02614C14.3913 3.05028 15.0001 3.68393 15.0001 4.47819V4.59082C14.0078 4.53056 13.0075 4.5 12.0001 4.5C10.9928 4.5 9.99249 4.53056 9.00012 4.59082V4.47819C9.00012 3.68393 9.6089 3.05028 10.3635 3.02614ZM10.0087 8.97118C9.9928 8.55727 9.64436 8.23463 9.23045 8.25055C8.81654 8.26647 8.49391 8.61492 8.50983 9.02882L8.85599 18.0288C8.8719 18.4427 9.22035 18.7654 9.63426 18.7494C10.0482 18.7335 10.3708 18.3851 10.3549 17.9712L10.0087 8.97118ZM15.4895 9.02882C15.5054 8.61492 15.1828 8.26647 14.7689 8.25055C14.355 8.23463 14.0065 8.55727 13.9906 8.97118L13.6444 17.9712C13.6285 18.3851 13.9512 18.7335 14.3651 18.7494C14.779 18.7654 15.1274 18.4427 15.1433 18.0288L15.4895 9.02882Z" fill="currentColor"></path></svg>
        </div>
      </div>
      <label>
        {{ $t('form.name') }}*
      </label>
      <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
      <input type="text" v-model="copyRoutine.name" :placeholder="$t('dashboard.forms.namePlaceholder')">
      <label>
        {{ $t('form.url') }}*
      </label>
      <info-comp class="error" :message="status" v-if="status.length"/>
      <info-comp class="error" :complex="$v.path.$errors[0]" v-if="$v.path.$error"/>
      <input type="text" v-model="copyRoutine.path" :placeholder="$t('dashboard.forms.pathPlaceholder')">
      <label>
        {{ $t('form.schedule') }}*
      </label>
      <info-comp class="error" :complex="$v.schedule.$errors[0]" v-if="$v.schedule.$error"/>
      <div class="flex flex-row gap-4 items-center mb-5">
        <input type="radio" value="daily" v-model="copyRoutine.schedule" />
        <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
          {{ $t('form.daily') }}
        </label>
        <input type="radio" value="weekly" v-model="copyRoutine.schedule" />
        <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
          {{ $t('form.weekly') }}
        </label>
        <input type="radio" value="monthly" v-model="copyRoutine.schedule" />
        <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
          {{ $t('form.monthly') }}
        </label>
      </div>
      <label>
        {{ $t('form.devices') }}*
      </label>
      <info-comp class="error" :complex="$v.mobile.$errors[0]" v-if="$v.mobile.$error"/>
      <div class="flex flex-row gap-4 items-center mb-5">
        <input type="checkbox" v-model="copyRoutine.desktop" />
        <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
          {{ $t('form.desktop') }}
        </label>
        <input type="checkbox" v-model="copyRoutine.mobile" />
        <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
          {{ $t('form.mobile') }}
        </label>
      </div>
      <div v-if="copyRoutine.mobile">
        <label>
          {{ $t('form.emulate') }}*
        </label>
        <info-comp class="error" :complex="$v.emulate.$errors[0]" v-if="$v.emulate.$error"/>
        <drop-down-comp :items="dropDownPhone" @selected="(selected) => copyRoutine.emulate = selected" :selected="copyRoutine.emulate === '' ? null : copyRoutine.emulate"/>
      </div>
      <div class="flex flex-row justify-evenly w-full mt-4">
        <button class="btn w-full" @click="editRoutine">{{ $t('form.saveChanges') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
