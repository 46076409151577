<script setup lang="ts">
import InfoComp from '@/components/basic/InfoComp.vue'
import { competitorType as addNewCompetitorType, competitorType } from '@/interfaces/competitors'
import { computed, PropType, ref, reactive, getCurrentInstance } from 'vue'
import { maxLength, required, url } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import { del, patch } from '@/modules/asyncData'
import { competitorStore } from '@/stores/competitorStore'
import ModalComp from '@/components/basic/ModalComp.vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  competitor: {
    type: Object as PropType<competitorType>,
    required: true
  }
})

const competitors = competitorStore()
const router = useRouter()

const changedCompetitor = reactive<competitorType>(JSON.parse(JSON.stringify(props.competitor)))

const rules = computed(() => {
  return {
    name: { required },
    url: { required, url },
    description: { maxLength: maxLength(512) }
  }
})

const status = ref<string>('')

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const $v = useValidate(rules, changedCompetitor)
const saveChanges = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const competitorResult = await patch<addNewCompetitorType>('competitor', { id: props.competitor?.id, name: changedCompetitor.name, url: changedCompetitor.url, description: changedCompetitor.description })
      const oldCompetitor = competitors.competitors.find(obj => obj.id === props.competitor.id)
      Object.assign(oldCompetitor, competitorResult.data)
      status.value = 'competitor.tabs.settings.success'
      $Progress.finish()
    } catch (e) {
      status.value = 'competitor.tabs.settings.error'
      $Progress.fail()
    }
  }
}

const showDeleteModal = ref<boolean>(false)
const loading = ref<boolean>(false)
const delCompetitor = async () => {
  loading.value = true
  try {
    await del('competitor', { id: props.competitor.id })
    await competitors.loadAll()
    await router.push({ name: 'dashboard' })
  } catch (e) {
    console.log(e)
  }
  loading.value = false
}

</script>

<template>
  <div class="flex flex-col w-full p-10">
    <modal-comp v-if="showDeleteModal" @close-modal="showDeleteModal = false">
      <loader-comp size="small" v-if="loading" />
      <div class="flex flex-col gap-4">
        <div class="text-base font-semibold">{{ $t('form.areYouSure') }}</div>
        <div class="flex flex-row justify-around">
          <div class="btn secondary hover:scale-105" @click="showDeleteModal = false;">{{ $t('basic.cancel') }}</div>
          <div class="btn hover:scale-105" @click="delCompetitor()">{{ $t('basic.yes') }}</div>
        </div>
      </div>
    </modal-comp>
    <div class="text-lg font-bold mb-2.5">
      {{ $t('competitor.tabs.settings.subTitle') }}
    </div>
    <info-comp class="my-4" :class="status === 'competitor.tabs.account.settings' ? 'error' : 'success'" v-if="status" :message="status"/>
    <label>
      {{ $t('dashboard.forms.name') }}*
    </label>
    <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
    <input type="text" v-model="changedCompetitor.name" :placeholder="$t('dashboard.forms.namePlaceholder')">
    <label>
      {{ $t('dashboard.forms.url') }}*
    </label>
    <info-comp class="error" :complex="$v.url.$errors[0]" v-if="$v.url.$error"/>
    <input type="text" v-model="changedCompetitor.url" :placeholder="$t('dashboard.forms.urlPlaceholder')">
    <label>
      {{ $t('dashboard.forms.description') }}
    </label>
    <info-comp class="error" :complex="$v.description.$errors[0]" v-if="$v.description.$error"/>
    <input type="text" v-model="changedCompetitor.description" :placeholder="$t('dashboard.forms.descriptionPlaceholder')">
    <div class="flex flex-col justify-evenly w-full mt-4 gap-2">
      <button class="btn w-full" @click="saveChanges()">{{ $t('form.saveChanges') }}</button>
      <button class="btn secondary w-full" @click="showDeleteModal = true">{{ $t('form.delete') }}</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
