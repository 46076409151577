<script setup lang="ts">
import { computed, ref, reactive, PropType, getCurrentInstance } from 'vue'
import AddRoutineComp from '@/components/routines/AddRoutineComp.vue'
import ModalComp from '@/components/basic/ModalComp.vue'
import InfoComp from '@/components/basic/InfoComp.vue'
import { required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import { competitorType, routineNewsletterType } from '@/interfaces/competitors'
import { put } from '@/modules/asyncData'
import RoutineComp from '@/components/routines/RoutineComp.vue'
import Screenshots from '@/components/screenshots/ScreenshotsComp.vue'
import { authStore } from '@/stores/authStore'
import EditNewsletterComp from '@/components/routines/editNewsletterComp.vue'

const props = defineProps({
  competitor: {
    type: Object as PropType<competitorType>,
    required: true
  },
  newsletter: {
    type: [] as PropType<routineNewsletterType>,
    required: false
  }
})

const emits = defineEmits(['updateNewsletter'])

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const showAddModal = ref<boolean>(false)

const newRoutine = reactive<routineNewsletterType>({
  name: '',
  email: '',
  competitor: props.competitor.id
})

const rules = computed(() => {
  return {
    name: { required }
  }
})

const auth = authStore()
const currentRoutine = ref<string>('')
const editRoutine = ref<string>('')
const step = ref<number>(1)
const copied = ref<boolean>(false)

const $v = useValidate(rules, newRoutine)

const addRoutine = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const data = (await put('newsletter', newRoutine)).data
      newRoutine.email = data.email
      console.log(data.newsletter)
      emits('updateNewsletter', data.newsletter)
      step.value = 2
      $v.value.$reset()
      $Progress.finish()
    } catch (e) {
      $Progress.fail()
    }
  }
}

const reset = () => {
  showAddModal.value = false
  newRoutine.name = ''
  newRoutine.email = ''
  step.value = 1
  copied.value = false
}

const copy = () => {
  navigator.clipboard.writeText(newRoutine.email)
  copied.value = true
}

const deleteRoutine = (newsletter: routineNewsletterType[]) => {
  emits('updateNewsletter', newsletter)
  editRoutine.value = ''
  currentRoutine.value = ''
}

</script>

<template>
  <div>
    <modal-comp v-if="showAddModal" @close-modal="showAddModal = false; reset()">
      <div class="flex flex-col w-full">
        <div class="text-xl font-bold mb-5 flex flex-row justify-center items-center">
          {{ $t('competitor.forms.createNewScreening') }}
        </div>
        <label>
          {{ $t('form.name') }}*
        </label>
        <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
        <input type="text" v-model="newRoutine.name" :readonly="step === 2" :placeholder="$t('dashboard.forms.namePlaceholder')">
        <div class="flex flex-col w-full" v-if="newRoutine.email.length">
          <label>
            {{ $t('form.email') }}
          </label>
          <input type="text" readonly v-model="newRoutine.email" :placeholder="$t('dashboard.forms.pathPlaceholder')">
        </div>
        <div class="flex flex-row justify-evenly w-full mt-4">
          <button class="btn w-full" @click="addRoutine" v-if="step === 1">{{ $t('competitor.tabs.newsletter.addNewNewsletter') }}</button>
          <button class="btn w-full" @click="copy" v-else>{{ $t( copied ? 'competitor.tabs.newsletter.emailCopied' : 'competitor.tabs.newsletter.copyEmail') }}</button>
        </div>
        <div class="rounded-lg bg-stripe-billing font-normal text-sm px-6 py-3.5 mt-5 text-gray-800 dark:text-gray-1000">
          {{ step === 1 ? $t('competitor.tabs.newsletter.infoText1')  : $t('competitor.tabs.newsletter.infoText2', {placeholder: newRoutine.name}) }}
        </div>
      </div>
    </modal-comp>
    <modal-comp v-if="editRoutine" @close-modal="editRoutine = ''">
      <edit-newsletter-comp
        @changed="(routines) => {emits('updateNewsletter', routines); editRoutine = ''}"
        @status="(routines) => {emits('updateNewsletter', routines)}"
        @delete-routine="deleteRoutine"
        :routine="newsletter.find(routine => routine.id === editRoutine)"
        v-if="newsletter.find(routine => routine.id === editRoutine)"
      />
    </modal-comp>
    <div class="flex flex-col w-full p-10 pt-5">
      <div class="text-lg font-bold mb-2.5">{{ $t('competitor.tabs.newsletter.subTitle') }}</div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl::grid-cols-6 gap-6 flex-wrap">
        <routine-comp
          :active="currentRoutine === routine.id"
          v-for="routine in newsletter"
          :key="routine.id"
          :routine="routine"
          @changed-routine="(routineId) => {currentRoutine = routineId}"
          @edit-routine="(routineId) => {editRoutine = routineId}"
        />
        <add-routine-comp @add-new-routine="showAddModal = true" v-if="newsletter.length < auth.resources.routines" type="competitor.forms.createNewScreening"/>
      </div>
      <div class="w-full">
        <screenshots :routine-id="currentRoutine" :competitor="competitor"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
