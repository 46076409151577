<script setup lang="ts">
import { computed, ref, reactive, PropType, getCurrentInstance } from 'vue'
import AddRoutineComp from '@/components/routines/AddRoutineComp.vue'
import ModalComp from '@/components/basic/ModalComp.vue'
import InfoComp from '@/components/basic/InfoComp.vue'
import { helpers, required, requiredIf, url } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import { competitorType, routineType } from '@/interfaces/competitors'
import DropDownComp from '@/components/forms/DropDownComp.vue'
import { phones } from '@/constants/phones'
import { dropDown } from '@/interfaces/basic'
import { put } from '@/modules/asyncData'
import EditRoutineComp from '@/components/routines/editRoutineComp.vue'
import RoutineComp from '@/components/routines/RoutineComp.vue'
import Screenshots from '@/components/screenshots/ScreenshotsComp.vue'
import { authStore } from '@/stores/authStore'

const props = defineProps({
  competitor: {
    type: Object as PropType<competitorType>,
    required: true
  },
  routines: {
    type: [] as PropType<routineType>,
    required: false
  }
})

const showAddModal = ref<boolean>(false)

const emits = defineEmits(['updateRoutines'])

const newRoutine = reactive<routineType>({
  name: '',
  path: '',
  schedule: '',
  desktop: false,
  mobile: false,
  emulate: '',
  competitor: props.competitor.id
})

const rules = computed(() => {
  return {
    name: { required },
    path: { required, url },
    schedule: { required },
    mobile: {
      required: helpers.withParams(
        { type: 'device' },
        () => newRoutine.mobile || newRoutine.desktop)
    },
    desktop: {},
    emulate: { required: requiredIf(() => newRoutine.mobile) }
  }
})

const auth = authStore()
const currentRoutine = ref<string>('')
const editRoutine = ref<string>('')

const $v = useValidate(rules, newRoutine)

const dropDownPhone: dropDown[] = []

phones.forEach((phone: string) => { dropDownPhone.push({ value: phone, title: phone }) })

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const status = ref<string>('')
const addRoutine = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const routines = (await put<routineType>('routine', newRoutine)).data
      status.value = ''
      emits('updateRoutines', routines)
      $Progress.finish()
      showAddModal.value = false
      newRoutine.name = ''
      newRoutine.path = ''
      newRoutine.schedule = ''
      newRoutine.desktop = false
      newRoutine.mobile = false
      newRoutine.emulate = ''
      $v.value.$reset()
    } catch (e) {
      if (e.response && e.response.status === 406) {
        status.value = 'competitor.forms.differentHost'
      }
      $Progress.fail()
    }
  }
}

const deleteRoutine = (routines: routineType[]) => {
  emits('updateRoutines', routines)
  editRoutine.value = ''
  currentRoutine.value = ''
}

</script>

<template>
  <div>
    <modal-comp v-if="showAddModal" @close-modal="showAddModal = false">
      <div class="flex flex-col w-full">
        <div class="text-xl font-bold mb-5 flex flex-row justify-center items-center">
          {{ $t('competitor.forms.createNewRoutine') }}
        </div>
        <label>
          {{ $t('form.name') }}*
        </label>
        <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
        <input type="text" v-model="newRoutine.name" :placeholder="$t('dashboard.forms.namePlaceholder')">
        <label>
          {{ $t('form.url') }}*
        </label>
        <info-comp class="error" :message="status" v-if="status.length"/>
        <info-comp class="error" :complex="$v.path.$errors[0]" v-if="$v.path.$error"/>
        <input type="text" v-model="newRoutine.path" :placeholder="$t('dashboard.forms.pathPlaceholder')">
        <label>
          {{ $t('form.schedule') }}*
        </label>
        <info-comp class="error" :complex="$v.schedule.$errors[0]" v-if="$v.schedule.$error"/>
        <div class="flex flex-row gap-4 items-center mb-5">
          <input type="radio" value="daily" v-model="newRoutine.schedule" />
          <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
            {{ $t('form.daily') }}
          </label>
          <input type="radio" value="weekly" v-model="newRoutine.schedule" />
          <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
            {{ $t('form.weekly') }}
          </label>
          <input type="radio" value="monthly" v-model="newRoutine.schedule" />
          <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
            {{ $t('form.monthly') }}
          </label>
        </div>
        <label>
          {{ $t('form.devices') }}*
        </label>
        <info-comp class="error" :complex="$v.mobile.$errors[0]" v-if="$v.mobile.$error"/>
        <div class="flex flex-row gap-4 items-center mb-5">
          <input type="checkbox" v-model="newRoutine.desktop" />
          <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
            {{ $t('form.desktop') }}
          </label>
          <input type="checkbox" v-model="newRoutine.mobile" />
          <label class="text-sm font-medium min-w-[50px] flex flex-row items-center mb-0">
            {{ $t('form.mobile') }}
          </label>
        </div>
        <div v-if="newRoutine.mobile">
          <label>
            {{ $t('form.emulate') }}*
          </label>
          <info-comp class="error" :complex="$v.emulate.$errors[0]" v-if="$v.emulate.$error"/>
          <drop-down-comp :items="dropDownPhone" @selected="(selected) => newRoutine.emulate = selected"/>
        </div>
        <div class="flex flex-row justify-evenly w-full mt-4">
          <button class="btn w-full" @click="addRoutine">{{ $t('competitor.forms.createNewRoutine') }}</button>
        </div>

        <div class="rounded-lg bg-stripe-billing font-normal text-sm px-6 py-3.5 mt-5">
          {{ $t('competitor.tabs.website.infoText') }}
        </div>
      </div>
    </modal-comp>
    <modal-comp v-if="editRoutine" @close-modal="editRoutine = ''">
      <edit-routine-comp
        @changed="(routines) => {emits('updateRoutines', routines); editRoutine = ''}"
        @status="(routines) => {emits('updateRoutines', routines)}"
        @delete-routine="deleteRoutine"
        :routine="routines.find(routine => routine.id === editRoutine)"
        v-if="routines.find(routine => routine.id === editRoutine)"
      />
    </modal-comp>
    <div class="flex flex-col w-full p-10 pt-5">
      <div class="text-lg font-bold mb-2.5">{{ $t('competitor.tabs.website.subTitle') }}</div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl::grid-cols-6 gap-6 flex-wrap">
        <routine-comp
          :active="currentRoutine === routine.id"
          v-for="routine in routines"
          :key="routine.id"
          :routine="routine"
          @changed-routine="(routineId) => {currentRoutine = routineId}"
          @edit-routine="(routineId) => {editRoutine = routineId}"
        />
        <add-routine-comp @add-new-routine="showAddModal = true" v-if="routines.length < auth.resources.routines"  type="competitor.forms.createNewRoutine"/>
      </div>
      <div class="w-full">
        <screenshots :routine-id="currentRoutine" :competitor="competitor" :desktop="true" :mobile="true"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
