<script setup lang="ts">
import { infoStore } from '@/stores/infoStore'

const info = infoStore()
</script>

<template>
  <div class="pointer-events-none absolute top-14 right-0 z-[100] w-52 overflow-hidden">
    <transition-group name="list" class="relative h-[calc(100vh-4rem)]" tag="ul">
      <div v-for="message in info.messages" :key="message.id" class="w-48 rounded flex flex-col px-4 py-2 my-2 shadow" :class="message.type" @click="info.removeMessage(message)">
        <div class="font-bold text-medium">{{ $t(message.title) }}</div>
        <div class="text-sm">{{ $t(message.message) }}</div>
      </div>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from{
  opacity: 0;
  transform: translate(50px, -50px);
}
.list-leave-to {
  opacity: 0;
  transform: translate(50px, 0px);
}

.list-leave-active {
  position: absolute;
}
</style>
