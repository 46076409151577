import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { i18n } from '@/modules/i18n'
import { useRoute } from 'vue-router'
import { Language } from '@/interfaces/config'
import { get } from '@/modules/asyncData'
import { pricesType } from '@/interfaces/basic'

export const configStore = defineStore('config', () => {
  const projectURL = process.env.VUE_APP_DOMAIN ? `${process.env.VUE_APP_DOMAIN}/api/` : 'https://webrivals.co/api/'
  const dataURL = process.env.VUE_APP_DOMAIN ? `${process.env.VUE_APP_DOMAIN}/` : 'https://webrivals.co/'
  console.log(projectURL)
  const projectTitle = 'webRivals'
  const route = useRoute()

  const menu = ref<boolean>(false)

  const refDarkModeSelect = ref()
  const refLanguageSelect = ref()

  // ------------- LANGUAGE ---------------------

  const currentLanguage = reactive<Language>({ code: 'de', flag: 'de' })
  const languageMenu = ref<boolean>(false)
  const languages = ref<Language[]>([{ code: 'en', flag: 'gb' }, { code: 'de', flag: 'de' },])

  watch(currentLanguage, (value) => {
    i18n.global.locale = value.code
    languageMenu.value = false
    document.title = i18n.global.t(route?.meta?.title as string ?? '')
  })

  // ------------- DARKMODE ---------------------

  const systemDarkMode = ref<boolean>(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  const darkModeSettings = ref<'light' | 'dark' | 'system'>('light')
  const darkModeMenu = ref<boolean>(false)

  const darkMode = computed<'light' | 'dark'>(() => {
    const temp = darkModeSettings.value === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : darkModeSettings.value
    document.getElementsByTagName('html')[0].className = temp
    return temp
  })

  if (window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      systemDarkMode.value = event.matches
    })
  }

  watch(darkModeSettings, async (newValue) => {
    document.getElementsByTagName('html')[0].className = newValue === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : newValue
  })

  document.getElementsByTagName('html')[0].className = darkModeSettings.value === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : darkModeSettings.value

  const prices = reactive<pricesType>({
    plus: {
      monthly: {
        price: undefined,
        id: undefined
      },
      annually: {
        price: undefined,
        id: undefined
      }
    },
    pro: {
      monthly: {
        price: undefined,
        id: undefined
      },
      annually: {
        price: undefined,
        id: undefined
      }
    }
  })
  const loadPrices = async () => {
    try {
      const result = (await get('stripe/prices'))
      Object.assign(prices, result.data)
      console.log(prices, result.data)
    } catch (e) {
      console.log(e)
    }
  }

  return { projectURL, dataURL, projectTitle, currentLanguage, languageMenu, languages, menu, darkMode, darkModeMenu, systemDarkMode, darkModeSettings, refDarkModeSelect, refLanguageSelect, loadPrices, prices }
},
{
  persist: {
    paths: ['currentLanguage', 'darkModeSettings']
  }
}
)
