<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue'
import { authStore } from '@/stores/authStore'
import InfoComp from '@/components/basic/InfoComp.vue'

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const email = ref<string>('')

const status = ref<string>('')

const auth = authStore()

const forgot = () => {
  $Progress.start()
  auth.forgotPassword(email.value)
    .then(() => {
      status.value = 'auth.forgot.complete'
      $Progress.finish()
    })
    .catch(() => {
      status.value = 'auth.forgot.error'
      $Progress.fail()
    })
}

</script>

<template>
  <div class="flex flex-col w-full mb-4">
    <button class="btn secondary mb-1.5" @click="$router.push({name: 'login'})">{{ $t('auth.login.loginNow') }}</button>
    <div class="flex flex-row justify-evenly w-full py-6">
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
      <div class="px-8 uppercase font-normal text-gray-300 text-sm">{{ $t('basic.or')}}</div>
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
    </div>
    <div class="flex justify-center items-center mb-5 text-xl font-bold">
      {{ $t('auth.forgot.title') }}
    </div>
    <label>
      {{ $t('form.email') }}
    </label>
    <input type="text" v-model="email">
  </div>
  <info-comp class="mb-2" :class="status === 'auth.forgot.error' ? 'error' : 'success'" :message="$t(status)" v-if="status"/>
  <div class="flex flex-row justify-evenly w-full">
    <button class="btn w-full" @click="forgot" v-if="status !== 'auth.forgot.complete'">{{ $t('auth.forgot.button') }}</button>
  </div>
  <div class="flex flex-row justify-evenly w-full pt-4">
    <span class="text-sm font-semibold hover:underline hover:cursor-pointer" @click="$router.push({name: 'register'})">{{ $t('auth.registration.register') }}</span>
  </div>
</template>

<style scoped lang="scss">

</style>
