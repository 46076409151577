import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { infoMessage } from '@/interfaces/info'
import moment from 'moment'

export const infoStore = defineStore('info', () => {
  const messages = reactive<infoMessage[]>([])
  const counter = ref<number>(0)
  const messageTTL = 5
  const addMessage = (message: infoMessage):void => {
    message.id = counter.value
    message.timeStamp = moment().unix()
    counter.value = counter.value + 1
    messages.unshift(message)
  }

  setInterval(() => {
    messages.forEach(message => {
      // @ts-ignore
      if (message.timeStamp + messageTTL < moment().unix() && !message.keep) removeMessage(message)
    })
  }, 100)

  const removeMessage = (message: infoMessage):void => {
    messages.splice(messages.indexOf(message), 1)
  }

  return { messages, addMessage, removeMessage }
},
{
  persist: {
    paths: ['messages', 'counter']
  }
})
