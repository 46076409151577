import axios from 'axios'
import { authStore } from '@/stores/authStore'
interface AxiosResponse<T> {
  status: number,
  data: T
}

export const get = async <T>(url: string, params: object = {}, headers: object = {}): Promise<AxiosResponse<T>> => {
  const auth = authStore()
  await auth.checkJwt()
  return new Promise((resolve, reject) => {
    axios.get(url, { headers, params })
      .then(r => {
        if (r.data.newJwtToken) {
          auth.setJwt(r.data.newJwtToken)
        }

        resolve({ status: r.status, data: r.data })
      })
      .catch(async e => {
        if (e.response?.status === 403) {
          await auth.logout()
        }
        reject(e)
      })
  })
}

export const post = async <T>(url: string, data: object = {}, headers: object = {}): Promise<AxiosResponse<T>> => {
  const auth = authStore()
  await auth.checkJwt()
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers })
      .then(r => {
        if (r.data.newJwtToken) {
          auth.setJwt(r.data.newJwtToken)
        } else if (r.data.logout) {
          auth.logout()
        }

        resolve({ status: r.status, data: r.data })
      })
      .catch(async e => {
        if (e.response?.status === 403) {
          await auth.logout()
        }
        reject(e)
      })
  })
}

export const put = async <T>(url: string, data : object = {}, headers: object = {}): Promise<AxiosResponse<T>> => {
  const auth = authStore()
  auth.checkJwt()
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers })
      .then(r => {
        if (r.data.newJwtToken) {
          auth.setJwt(r.data.newJwtToken)
        } else if (r.data.logout) {
          auth.logout()
        }

        resolve({ status: r.status, data: r.data })
      })
      .catch(async e => {
        if (e.response?.status === 403) {
          await auth.logout()
        }
        reject(e)
      })
  })
}

export const patch = async <T>(url: string, data : object = {}, headers: object = {}): Promise<AxiosResponse<T>> => {
  const auth = authStore()
  auth.checkJwt()
  return new Promise((resolve, reject) => {
    axios.patch(url, data, { headers })
      .then(r => {
        if (r.data.newJwtToken) {
          auth.setJwt(r.data.newJwtToken)
        } else if (r.data.logout) {
          auth.logout()
        }

        resolve({ status: r.status, data: r.data })
      })
      .catch(async e => {
        if (e.response?.status === 403) {
          await auth.logout()
        }
        reject(e)
      })
  })
}

export const del = async <T>(url: string, data: object = {}, headers: object = {}): Promise<AxiosResponse<T>> => {
  const auth = authStore()
  auth.checkJwt()
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers, data })
      .then(r => {
        if (r.data.newJwtToken) {
          auth.setJwt(r.data.newJwtToken)
        } else if (r.data.logout) {
          auth.logout()
        }

        resolve({ status: r.status, data: r.data })
      })
      .catch(async e => {
        if (e.response?.status === 403) {
          await auth.logout()
        }
        reject(e)
      })
  })
}
