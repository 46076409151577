<script setup lang="ts">
import { competitorType, screenshotType } from '@/interfaces/competitors'
import { configStore } from '@/stores/configStore'
import moment from 'moment'
import { ref } from 'vue'
import ModalComp from '@/components/basic/ModalComp.vue'
import { patch } from '@/modules/asyncData'
import downloadIcon from '@material-design-icons/svg/round/download.svg'
import ShareComp from '@/components/basic/ShareComp.vue'

interface propType {
  screenshot: screenshotType
  competitor: competitorType
}
const props = defineProps<propType>()

const emits = defineEmits(['favorite'])

const config = configStore()

const date = moment.unix(Math.round((props.screenshot.createdAt ?? 0) / 1000)).locale(config.currentLanguage.code)

const showBigModal = ref<boolean>(false)
const showShareModal = ref<boolean>(false)

const viewerOptions = {
  url: 'data-src',
  navbar: false,
  title: false,
  toolbar: false,
  rotatable: false,
  minZoomRatio: 0.1,
  maxZoomRatio: 2,
  zoomRatio: 0.5
}

const favorite = async () => {
  try {
    patch('screenshot', { screenshotId: props.screenshot.id, favorite: !props.screenshot.favorite })
  } catch (e) {}
  emits('favorite')
}

const show = () => {
  document.querySelector('#v-viewer')?.$viewer.show()
}

</script>

<template>
  <div class="w-full">
    <modal-comp v-if="showBigModal" @close-modal="() => {showBigModal = false;}">
      <div class="flex flex-col justify-center items-center text-black dark:text-white h-full max-h-[80vh]">
        <div class="text-xl font-bold">
          {{ screenshot.mailId ? screenshot.subject : competitor.name}}
        </div>
        <div class="text-sm">
          {{date.format('DD MMM YYYY, zz HH:mm')}}
        </div>
        <div class="flex flex-row mt-2.5 gap-5">
          <svg @click="favorite()" class="w-6 h-6" :class="screenshot.favorite ? 'text-love hover:text-gray-100' : 'text-gray-100 hover:text-love'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.645 20.9107L11.6384 20.9072L11.6158 20.8949C11.5965 20.8844 11.5689 20.8693 11.5336 20.8496C11.4629 20.8101 11.3612 20.7524 11.233 20.6769C10.9765 20.5261 10.6132 20.3039 10.1785 20.015C9.31074 19.4381 8.15122 18.5901 6.9886 17.5063C4.68781 15.3615 2.25 12.1751 2.25 8.25C2.25 5.32194 4.7136 3 7.6875 3C9.43638 3 11.0023 3.79909 12 5.0516C12.9977 3.79909 14.5636 3 16.3125 3C19.2864 3 21.75 5.32194 21.75 8.25C21.75 12.1751 19.3122 15.3615 17.0114 17.5063C15.8488 18.5901 14.6893 19.4381 13.8215 20.015C13.3868 20.3039 13.0235 20.5261 12.767 20.6769C12.6388 20.7524 12.5371 20.8101 12.4664 20.8496C12.4311 20.8693 12.4035 20.8844 12.3842 20.8949L12.3616 20.9072L12.355 20.9107L12.3523 20.9121C12.1323 21.0289 11.8677 21.0289 11.6477 20.9121L11.645 20.9107Z" fill="currentColor"></path></svg>
          <div class="group relative">
            <svg class="w-6 h-6 text-gray-200 hover:text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="showShareModal = true"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 4.5C15.75 2.84315 17.0931 1.5 18.75 1.5C20.4069 1.5 21.75 2.84315 21.75 4.5C21.75 6.15685 20.4069 7.5 18.75 7.5C17.8933 7.5 17.1212 7.14074 16.5751 6.56624L8.15392 11.2447C8.21665 11.4863 8.25 11.7395 8.25 12C8.25 12.2605 8.21665 12.5137 8.15392 12.7553L16.5751 17.4338C17.1212 16.8593 17.8933 16.5 18.75 16.5C20.4069 16.5 21.75 17.8431 21.75 19.5C21.75 21.1569 20.4069 22.5 18.75 22.5C17.0931 22.5 15.75 21.1569 15.75 19.5C15.75 19.2395 15.7833 18.9863 15.8461 18.7447L7.42488 14.0662C6.87885 14.6407 6.10667 15 5.25 15C3.59315 15 2.25 13.6569 2.25 12C2.25 10.3431 3.59315 9 5.25 9C6.10667 9 6.87885 9.35926 7.42488 9.93377L15.8461 5.25532C15.7833 5.01367 15.75 4.76045 15.75 4.5Z" fill="currentColor"></path></svg>
            <share-comp :text="$t('competitor.share', {msg: config.dataURL  +  'share/' + screenshot.hash })" :link="config.dataURL  +  'share/' + screenshot.hash "/>
          </div>
          <a :href="config.dataURL  +  'data/screenshots/' + screenshot.hash + '.jpg'" target="_blank" :download="(screenshot.mailId ? screenshot.subject : competitor.name) + date.format('DD MMM YYYY') + '.jpg'">
            <downloadIcon class="w-6 h-6 text-gray-200 hover:text-gray-400" />
          </a>
        </div>
        <div id="v-viewer" class="max-w-full cursor-pointer h-auto mt-5 border border-gray-100 transition-all hover:scale-105 hover:shadow overflow-hidden" v-viewer="viewerOptions">
          <img class="h-auto"  alt="screenshot" :src="config.dataURL  +  'data/screenshots/' + screenshot.hash + '_thumb.jpg'" :data-src="config.dataURL  +  'data/screenshots/' + screenshot.hash + '.jpg'"/>
        </div>
        <div class="text-base dark:text-gray-10 italic mt-2 text-font-normal cursor-pointer" @click="show">
          {{ $t('competitor.click') }}
        </div>
      </div>
    </modal-comp>
    <div class="cursor-pointer shadow-cards hover:shadow-cards-hover overflow-hidden h-[250px] border-gray-25 rounded-xl flex flex-col justify-between items-center text-gray-500 border group" @click="showBigModal = true">
      <div class="w-full flex-grow bg-cover bg-center" :style="{'background-image': 'url(\'' + config.dataURL  +  'data/screenshots/' + screenshot.hash + '_thumb.jpg\')'}"></div>
      <div class="flex flex-row flex-grow-0 justify-between items-center gap-2 px-3 py-4 text-black dark:text-white w-full">
        <div class="flex flex-col items-start justify-center">
          <div class="text-sm font-bold">
            {{ date.format('DD MMM YYYY') }}
          </div>
          <div class="text-xs font-normal">
            {{ date.format('zz HH:mm') }}
          </div>
        </div>
        <div class="flex flex-row justify-center items-center gap-2">
          <svg class="w-6 h-6 text-gray-200 group-hover:text-gray-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.75C15 3.33579 15.3358 3 15.75 3L20.25 3C20.6642 3 21 3.33579 21 3.75V8.25C21 8.66421 20.6642 9 20.25 9C19.8358 9 19.5 8.66421 19.5 8.25V5.56066L15.5303 9.53033C15.2374 9.82322 14.7626 9.82322 14.4697 9.53033C14.1768 9.23744 14.1768 8.76256 14.4697 8.46967L18.4393 4.5H15.75C15.3358 4.5 15 4.16421 15 3.75ZM3 3.75C3 3.33579 3.33579 3 3.75 3H8.25C8.66421 3 9 3.33579 9 3.75C9 4.16421 8.66421 4.5 8.25 4.5H5.56066L9.53033 8.46967C9.82322 8.76256 9.82322 9.23744 9.53033 9.53033C9.23744 9.82322 8.76256 9.82322 8.46967 9.53033L4.5 5.56066V8.25C4.5 8.66421 4.16421 9 3.75 9C3.33579 9 3 8.66421 3 8.25V3.75ZM14.4697 15.5303C14.1768 15.2374 14.1768 14.7626 14.4697 14.4697C14.7626 14.1768 15.2374 14.1768 15.5303 14.4697L19.5 18.4393V15.75C19.5 15.3358 19.8358 15 20.25 15C20.6642 15 21 15.3358 21 15.75V20.25C21 20.6642 20.6642 21 20.25 21H15.75C15.3358 21 15 20.6642 15 20.25C15 19.8358 15.3358 19.5 15.75 19.5H18.4393L14.4697 15.5303ZM9.53033 14.4697C9.82322 14.7626 9.82322 15.2374 9.53033 15.5303L5.56066 19.5H8.25C8.66421 19.5 9 19.8358 9 20.25C9 20.6642 8.66421 21 8.25 21H3.75C3.33579 21 3 20.6642 3 20.25V15.75C3 15.3358 3.33579 15 3.75 15C4.16421 15 4.5 15.3358 4.5 15.75V18.4393L8.46967 14.4697C8.76256 14.1768 9.23744 14.1768 9.53033 14.4697Z" fill="currentColor"></path></svg>
          <svg @click.prevent.stop="favorite()" class="w-6 h-6" :class="screenshot.favorite ? 'text-love hover:text-gray-100' : 'text-gray-100 hover:text-love'" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.645 20.9107L11.6384 20.9072L11.6158 20.8949C11.5965 20.8844 11.5689 20.8693 11.5336 20.8496C11.4629 20.8101 11.3612 20.7524 11.233 20.6769C10.9765 20.5261 10.6132 20.3039 10.1785 20.015C9.31074 19.4381 8.15122 18.5901 6.9886 17.5063C4.68781 15.3615 2.25 12.1751 2.25 8.25C2.25 5.32194 4.7136 3 7.6875 3C9.43638 3 11.0023 3.79909 12 5.0516C12.9977 3.79909 14.5636 3 16.3125 3C19.2864 3 21.75 5.32194 21.75 8.25C21.75 12.1751 19.3122 15.3615 17.0114 17.5063C15.8488 18.5901 14.6893 19.4381 13.8215 20.015C13.3868 20.3039 13.0235 20.5261 12.767 20.6769C12.6388 20.7524 12.5371 20.8101 12.4664 20.8496C12.4311 20.8693 12.4035 20.8844 12.3842 20.8949L12.3616 20.9072L12.355 20.9107L12.3523 20.9121C12.1323 21.0289 11.8677 21.0289 11.6477 20.9121L11.645 20.9107Z" fill="currentColor"></path></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
