<script setup lang="ts">

import InfoComp from '@/components/basic/InfoComp.vue'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { minLength, required, sameAs } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'
import PasswordComp from '@/components/forms/PasswordComp.vue'
import { authStore } from '@/stores/authStore'

const status = ref<string>('')

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const user = reactive({
  currentPassword: '',
  password: '',
  password2: ''
})

const rules = computed(() => {
  return {
    currentPassword: { required },
    password: { required, minLength: minLength(8) },
    password2: { required, sameAs: sameAs(user.password) }
  }
})

const auth = authStore()

const $v = useValidate(rules, user)

const changePassword = async (): Promise<void> => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    $Progress.start()
    auth.updatePassword(user).then(() => {
      status.value = 'settings.tabs.password.success'
      $Progress.finish()
    })
      .catch(unused => {
        $Progress.fail()
        status.value = 'settings.tabs.password.error'
      })
  }
}
</script>

<template>
  <div class="flex flex-col w-full">
    <div class="text-lg font-bold mb-2.5">{{ $t('settings.tabs.password.subTitle') }}</div>
    <info-comp class="my-4" :class="status === 'settings.tabs.password.error' ? 'error' : 'success'" v-if="status" :message="status"/>
    <label>
      {{ $t('form.currentPassword') }}*
    </label>
    <info-comp class="error" :complex="$v.currentPassword.$errors[0]" v-if="$v.currentPassword.$error"/>
    <password-comp @typed="typed => {user.currentPassword = typed}" />
    <label>
      {{ $t('form.newPassword') }}*
    </label>
    <info-comp class="error" :complex="$v.password.$errors[0]" v-if="$v.password.$error"/>
    <password-comp @typed="typed => {user.password = typed}" />
    <label>
      {{ $t('form.password2') }}*
    </label>
    <info-comp class="error" :complex="$v.password2.$errors[0]" v-if="$v.password2.$error"/>
    <password-comp @typed="typed => {user.password2 = typed}" />
    <div class="flex flex-row justify-start w-full mt-4">
      <button class="btn" @click="changePassword()">{{ $t('form.saveChanges') }}</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
