<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  message?: string,
  complex?: object
}>()

const maxHeight = ref<number>(0)

setTimeout(() => {
  maxHeight.value = 999
}, 10)
</script>

<template>
  <div class="pointer-events-none py-1 px-2 text-sm relative transition-all duration-500 rounded shadow w-full mb-2" :style="{'max-height': maxHeight + 'px'}">
    <span v-if="message">
      {{ $te(message) ? $t(message) : message }}
    </span>
    <span v-else-if="typeof complex !== 'undefined'">
      {{ $t('form.error.' + complex?.$params.type, {msg: complex?.$params.min ?? complex?.$params.max, field: $te('form.' + complex?.$property) ? $t('form.' + complex?.$property) : ''}) }}
    </span>
  </div>
</template>

<style scoped lang="scss">

</style>
