import { createI18n } from 'vue-i18n'
import deLocale from '@/locales/de.json'
import enLocale from '@/locales/en.json'

export const i18n = createI18n<typeof deLocale, string>({
  // legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: enLocale,
    de: deLocale
  }
})
