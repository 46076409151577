<script setup lang="ts">
const emits = defineEmits(['addNewCompetitor'])
</script>

<template>
  <div
    class="h-[200px] cursor-pointer border-dashed border-gray-50 rounded-xl flex flex-col justify-center items-center dark:text-white text-gray-500 border hover:bg-gray-10 dark:hover:bg-gray-600"
    @click="emits('addNewCompetitor')"
  >
    <svg class="w-12 h-12 mb-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 3C4.34315 3 3 4.34315 3 6V8.25C3 9.90685 4.34315 11.25 6 11.25H8.25C9.90685 11.25 11.25 9.90685 11.25 8.25V6C11.25 4.34315 9.90685 3 8.25 3H6Z" fill="currentColor"></path>
      <path d="M15.75 3C14.0931 3 12.75 4.34315 12.75 6V8.25C12.75 9.90685 14.0931 11.25 15.75 11.25H18C19.6569 11.25 21 9.90685 21 8.25V6C21 4.34315 19.6569 3 18 3H15.75Z" fill="currentColor"></path><path d="M6 12.75C4.34315 12.75 3 14.0931 3 15.75V18C3 19.6569 4.34315 21 6 21H8.25C9.90685 21 11.25 19.6569 11.25 18V15.75C11.25 14.0931 9.90685 12.75 8.25 12.75H6Z" fill="currentColor"></path><path d="M17.625 13.5C17.625 13.0858 17.2892 12.75 16.875 12.75C16.4608 12.75 16.125 13.0858 16.125 13.5V16.125H13.5C13.0858 16.125 12.75 16.4608 12.75 16.875C12.75 17.2892 13.0858 17.625 13.5 17.625H16.125V20.25C16.125 20.6642 16.4608 21 16.875 21C17.2892 21 17.625 20.6642 17.625 20.25V17.625H20.25C20.6642 17.625 21 17.2892 21 16.875C21 16.4608 20.6642 16.125 20.25 16.125H17.625V13.5Z" fill="currentColor"></path>
    </svg>
    <div class="text-sm font-medium">
      {{ $t('dashboard.addNewCompetitor') }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
