import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { competitorType } from '@/interfaces/competitors'
import { get } from '@/modules/asyncData'
import { authStore } from '@/stores/authStore'
import moment from 'moment/moment'

export const competitorStore = defineStore('competitor', () => {
  const competitors = reactive<competitorType[]>([])

  const loaded = ref<boolean>(false)

  const lastUpdate = ref<number>(0)
  const loadAll = async () => {
    const auth = authStore()
    if (!auth.isAuth) return
    lastUpdate.value = moment().unix()
    try {
      const result = await get<competitorType[]>('competitors')
      competitors.splice(0)
      competitors.push(...result.data)
    } catch (e) {}
    loaded.value = true
  }

  const loadInterval = setInterval(loadAll, 60000)

  return { competitors, loadAll, loaded, lastUpdate, loadInterval }
},
{
  persist: {
    paths: ['competitors']
  }
})
