<script setup lang="ts">
import InfoComp from '@/components/basic/InfoComp.vue'
import { computed, getCurrentInstance, reactive, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { del, patch } from '@/modules/asyncData'
import useValidate from '@vuelidate/core'
import { routineNewsletterType } from '@/interfaces/competitors'
import ModalComp from '@/components/basic/ModalComp.vue'

interface propsType {
  routine: routineNewsletterType
}
const props = defineProps<propsType>()

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const emits = defineEmits(['changed', 'deleteRoutine'])

const copyRoutine = reactive<routineNewsletterType>(JSON.parse(JSON.stringify(props.routine)))

const rules = computed(() => {
  return {
    name: { required }
  }
})

const $v = useValidate(rules, copyRoutine)

const editRoutine = async () => {
  await $v.value.$validate()
  if (!$v.value.$error) {
    try {
      $Progress.start()
      const routine = (await patch<routineNewsletterType>('newsletter', copyRoutine)).data
      emits('changed', routine)
      $Progress.finish()
    } catch (e) {
      $Progress.fail()
    }
  }
}

const showDeleteModal = ref<boolean>(false)
const delRoutine = async () => {
  try {
    $Progress.start()
    const newsletters = (await del('newsletter', { id: copyRoutine.id })).data
    emits('deleteRoutine', newsletters)
    $Progress.finish()
  } catch (e) {
    $Progress.fail()
  }
}

const copied = ref<boolean>(false)
const copy = () => {
  navigator.clipboard.writeText(copyRoutine.email)
  copied.value = true
}
</script>

<template>
  <div class="w-full">
    <modal-comp v-if="showDeleteModal" @close-modal="showDeleteModal = false">
      <div class="flex flex-col gap-4">
        <div class="text-base font-semibold">{{ $t('form.areYouSure') }}</div>
        <div class="flex flex-row justify-around">
          <div class="btn secondary hover:scale-105" @click="showDeleteModal = false;">{{ $t('basic.cancel') }}</div>
          <div class="btn hover:scale-105" @click="delRoutine()">{{ $t('basic.yes') }}</div>
        </div>
      </div>
    </modal-comp>
    <div class="flex flex-col w-full">
      <div class="text-xl font-bold mb-5 flex flex-row justify-center items-center">
        <span class="mr-2 text-lg text-routine-play">&#x2022;</span>{{ $t('competitor.tabs.newsletter.edit') }}
      </div>
      <div class="flex flex-row justify-center items-center mb-5 gap-4">
        <div class="p-1.5 bg-routine-deleteBackground rounded-lg hover:scale-110" @click="showDeleteModal = true">
          <svg class="w-6 h-6 text-routine-delete" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5001 4.47819V4.70498C17.4548 4.79237 18.4017 4.90731 19.3398 5.04898C19.6871 5.10143 20.0332 5.15755 20.3781 5.2173C20.7863 5.28799 21.0598 5.67617 20.9891 6.0843C20.9184 6.49244 20.5302 6.76598 20.1221 6.69529C20.0525 6.68323 19.9829 6.67132 19.9131 6.65957L18.9077 19.7301C18.7875 21.2931 17.4842 22.5 15.9166 22.5H8.08369C6.51608 22.5 5.21276 21.2931 5.09253 19.7301L4.0871 6.65957C4.0174 6.67132 3.94774 6.68323 3.87813 6.69529C3.47 6.76598 3.08183 6.49244 3.01113 6.0843C2.94043 5.67617 3.21398 5.28799 3.62211 5.2173C3.96701 5.15755 4.31315 5.10143 4.66048 5.04898C5.59858 4.90731 6.5454 4.79237 7.50012 4.70498V4.47819C7.50012 2.91371 8.71265 1.57818 10.3156 1.52691C10.8749 1.50901 11.4365 1.5 12.0001 1.5C12.5638 1.5 13.1253 1.50901 13.6847 1.52691C15.2876 1.57818 16.5001 2.91371 16.5001 4.47819ZM10.3635 3.02614C10.9069 3.00876 11.4525 3 12.0001 3C12.5478 3 13.0934 3.00876 13.6367 3.02614C14.3913 3.05028 15.0001 3.68393 15.0001 4.47819V4.59082C14.0078 4.53056 13.0075 4.5 12.0001 4.5C10.9928 4.5 9.99249 4.53056 9.00012 4.59082V4.47819C9.00012 3.68393 9.6089 3.05028 10.3635 3.02614ZM10.0087 8.97118C9.9928 8.55727 9.64436 8.23463 9.23045 8.25055C8.81654 8.26647 8.49391 8.61492 8.50983 9.02882L8.85599 18.0288C8.8719 18.4427 9.22035 18.7654 9.63426 18.7494C10.0482 18.7335 10.3708 18.3851 10.3549 17.9712L10.0087 8.97118ZM15.4895 9.02882C15.5054 8.61492 15.1828 8.26647 14.7689 8.25055C14.355 8.23463 14.0065 8.55727 13.9906 8.97118L13.6444 17.9712C13.6285 18.3851 13.9512 18.7335 14.3651 18.7494C14.779 18.7654 15.1274 18.4427 15.1433 18.0288L15.4895 9.02882Z" fill="currentColor"></path></svg>
        </div>
      </div>
      <label>
        {{ $t('form.name') }}*
      </label>
      <info-comp class="error" :complex="$v.name.$errors[0]" v-if="$v.name.$error"/>
      <input type="text" v-model="copyRoutine.name" :placeholder="$t('dashboard.forms.namePlaceholder')">
      <label>
        {{ $t('form.email') }}
      </label>
      <input type="text" v-model="copyRoutine.email" readonly>
      <div class="flex flex-col justify-evenly w-full mt-4 gap-4">
        <button class="btn secondary w-full" @click="copy">{{ $t( copied ? 'competitor.tabs.newsletter.emailCopied' : 'competitor.tabs.newsletter.copyEmail') }}</button>
        <button class="btn w-full" @click="editRoutine">{{ $t('form.saveChanges') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
