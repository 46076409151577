<script setup lang="ts">
import { PropType, ref } from 'vue'
import { dropDown } from '@/interfaces/basic'
import clickOutside from '@/modules/ClickOutside'

const props = defineProps({
  items: Array as PropType<Array<dropDown>>,
  selected: {
    required: false,
    type: String
  }
})
const emit = defineEmits(['selected'])

const usersToggle = ref(false)

const show = () => {
  usersToggle.value = !usersToggle.value
}

const componentRef = ref()
clickOutside(componentRef, () => { usersToggle.value = false })

const itemSelected = ref<string>(props.selected ?? 'basic.select')
const choose = (item) => {
  itemSelected.value = item.title
  emit('selected', item.value)
  usersToggle.value = false
}

</script>

<template>
  <div class="relative mt-1.5" ref="componentRef">
    <div class="dropdown"
         @click="show"
         :class="{'ring-secondary-500': usersToggle, 'ring-gray-200': !usersToggle}"
    >
      {{ $t(itemSelected) }}
    </div>
    <div
      v-show="usersToggle"
      class="w-full shadow-xl rounded-xl py-0.5 absolute z-[1000] bg-white dark:bg-gray-700 dark:text-gray-100 border border-gray-50 mt-[-10px] max-h-[400px] overflow-y-auto"
    >
      <div v-for="item in items" :key="item.value" @click="choose(item)" class="select-none text-sm font-medium text-gray-600 dark:text-gray-100 mx-2 my-1 p-2.5 hover:bg-gray-25 dark:hover:bg-gray-900 rounded-lg transition-all duration-300">
        {{ $t(item.title) }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
