<script setup lang="ts">
import { ref } from 'vue'
import showIcon from '@material-design-icons/svg/round/visibility.svg'
import hideIcon from '@material-design-icons/svg/round/visibility_off.svg'

const emit = defineEmits(['typed', 'enter'])

const show = ref<boolean>(false)

</script>

<template>
  <div class="relative">
    <input class="w-full" :type="show ? 'text' : 'password'" @input="(event) => {emit('typed', event.target.value)}" @keydown.enter="emit('enter')">
    <div class="absolute right-3 top-0 flex justify-center items-center bottom-0 mb-5">
      <showIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100" v-if="!show" @click="show = !show"/>
      <hideIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100" v-else @click="show = !show"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
