<script setup lang="ts">
import { competitorType } from '@/interfaces/competitors'
import pendingIcon from '@material-design-icons/svg/round/pending.svg'
import { configStore } from '@/stores/configStore'
interface propTyoe {
  competitor: competitorType
}
const props = defineProps<propTyoe>()
const config = configStore()
</script>

<template>
  <component
    :is="competitor.frozen ? 'span' : 'router-link'"
    class="shadow-cards hover:shadow-cards-hover overflow-hidden h-[200px] border-gray-25 rounded-xl flex flex-col justify-between items-center text-gray-500 border"
    :class="competitor.frozen ? 'opacity-25' : 'hover:bg-gray-10 dark:hover:bg-gray-600'"
    :to="'competitor/' + competitor.id "
  >
    <div
      class="w-full flex-grow bg-cover bg-center bg-gray-200"
      :style="competitor.lastScreenshot ? 'background-image: url(\'' + config.dataURL  +  'data/preview/' + competitor.lastScreenshot + '.jpg\')' : ''"
    ></div>
    <div class="flex flex-row flex-grow-0 justify-between items-center gap-2 px-3 py-4 text-black dark:text-white w-full">
      <img class="w-5 h-5 flex-grow-0" alt="logo" :src="config.dataURL + 'data/logos/' + competitor.logo + '.png'" v-if="competitor.logo"/>
      <pendingIcon class="w-6 h-6 text-logo" v-else/>
      <div class="flex flex-col items-start justify-center flex-grow ml-2">
        <div class="text-sm font-bold">
          {{competitor.name}}
        </div>
        <div class="text-xs font-normal">
          {{competitor.url}}
        </div>
      </div>
      <router-link :to="'competitor/' + competitor.id + '/settings'" class="flex-grow-0">
        <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12C5.58579 12 5.25 11.6642 5.25 11.25L5.25002 3.75C5.25002 3.33578 5.5858 3 6.00002 3C6.41423 3 6.75002 3.33579 6.75002 3.75L6.75 11.25C6.75 11.6642 6.41421 12 6 12Z" fill="currentColor"></path><path d="M18 12C17.5858 12 17.25 11.6642 17.25 11.25L17.25 3.75C17.25 3.33578 17.5858 3 18 3C18.4142 3 18.75 3.33579 18.75 3.75L18.75 11.25C18.75 11.6642 18.4142 12 18 12Z" fill="currentColor"></path><path d="M6.75001 20.25L6.75 18.75C6.75 18.3358 6.41421 18 5.99999 18C5.58578 18 5.25 18.3358 5.25 18.75L5.25001 20.25C5.25002 20.6642 5.58581 21 6.00002 21C6.41424 21 6.75002 20.6642 6.75001 20.25Z" fill="currentColor"></path><path d="M18.75 18.75L18.75 20.25C18.75 20.6642 18.4142 21 18 21C17.5858 21 17.25 20.6642 17.25 20.25L17.25 18.75C17.25 18.3358 17.5858 18 18 18C18.4142 18 18.75 18.3358 18.75 18.75Z" fill="currentColor"></path><path d="M12.75 5.24999L12.75 3.74999C12.75 3.33578 12.4142 3 12 3C11.5858 3 11.25 3.33579 11.25 3.75001L11.25 5.25001C11.25 5.66422 11.5858 6 12 6C12.4142 6 12.75 5.66421 12.75 5.24999Z" fill="currentColor"></path><path d="M12 21C11.5858 21 11.25 20.6642 11.25 20.25V12.75C11.25 12.3358 11.5858 12 12 12C12.4142 12 12.75 12.3358 12.75 12.75V20.25C12.75 20.6642 12.4142 21 12 21Z" fill="currentColor"></path><path d="M3.75 15C3.75 16.2426 4.75736 17.25 6 17.25C7.24264 17.25 8.25 16.2426 8.25 15C8.25 13.7574 7.24264 12.75 6 12.75C4.75736 12.75 3.75 13.7574 3.75 15Z" fill="currentColor"></path><path d="M12 11.25C10.7574 11.25 9.75 10.2426 9.75 9C9.75 7.75736 10.7574 6.75 12 6.75C13.2426 6.75 14.25 7.75736 14.25 9C14.25 10.2426 13.2426 11.25 12 11.25Z" fill="currentColor"></path><path d="M15.75 15C15.75 16.2426 16.7574 17.25 18 17.25C19.2426 17.25 20.25 16.2426 20.25 15C20.25 13.7574 19.2426 12.75 18 12.75C16.7574 12.75 15.75 13.7574 15.75 15Z" fill="currentColor"></path></svg>
      </router-link>
    </div>
  </component>
</template>

<style scoped lang="scss">

</style>
