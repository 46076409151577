<script setup lang="ts">
import { get } from '@/modules/asyncData'
import { getCurrentInstance } from 'vue'

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress
$Progress.start()
try {
  const result = await get('stripe/prices')
  $Progress.finish()
} catch (e) {
  $Progress.fail()
}
</script>

<template>
 <div/>
</template>

<style scoped lang="scss">

</style>
