<script setup lang="ts">
import DropDownComp from "@/components/forms/DropDownComp.vue";
import {dropDown} from "@/interfaces/basic";
import {configStore} from "@/stores/configStore";
import {reactive} from "vue";
import {Language} from "@/interfaces/config";
import {post} from "@/modules/asyncData";

const config = configStore()
const languages: dropDown[] = reactive([
])

config.languages.forEach(language => {
  languages.push({ value: language.code, title: 'settings.tabs.ui.languages.' + language.code })
})

const selectLanguage = (languageSelected: string): void => {
  Object.assign(config.currentLanguage, config.languages.find(language => language.code === languageSelected))
  post('settings/language', { language: languageSelected }).catch((e: Error) => { console.log(e) })
}

const theme: dropDown[] = [
  { value: 'light', title: 'settings.tabs.ui.theme.light' },
  { value: 'dark', title: 'settings.tabs.ui.theme.dark' },
  { value: 'system', title: 'settings.tabs.ui.theme.system' }
]

const selectMode = (mode: 'light' | 'dark' | 'system'): void => {
  config.darkModeSettings = mode
  config.darkModeMenu = false

  post('settings/darkMode', { darkMode: mode }).catch((e: Error) => { console.log(e) })
}

</script>

<template>
  <div class="flex flex-col w-full">
    <div class="text-lg font-bold mb-2.5">{{ $t('settings.tabs.ui.subTitle') }}</div>
    <label>
      {{ $t('form.language') }}
    </label>
    <drop-down-comp :items="languages" @selected="selectLanguage" :selected="languages.find(language => language.value === config.currentLanguage.code)?.title"/>
    <label>
      {{ $t('form.design') }}
    </label>
    <drop-down-comp :items="theme" @selected="selectMode" :selected="theme.find(design => design.value === config.darkModeSettings)?.title"/>
  </div>
</template>

<style scoped lang="scss">

</style>
