<script setup lang="ts">
const emits = defineEmits(['addNewRoutine'])
const props = defineProps({
  type: String
})
</script>

<template>
  <div
    class="cursor-pointer h-[70px] p-4 border-dashed border-gray-50 rounded-xl flex flex-col justify-center items-center text-gray-500 dark:text-white border hover:bg-gray-10 dark:hover:bg-gray-600"
    @click="emits('addNewRoutine')"
  >
    <div class="text-2xl font-bold">
      +
    </div>
    <div class="text-sm font-bold">
      {{ $t(type) }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
