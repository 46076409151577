<script setup lang="ts">
import { configStore } from '@/stores/configStore'
import NavBarLandingComp from "@/components/landing/NavBarLandingComp.vue";
import {getCurrentInstance, reactive} from "vue";
import {get} from "@/modules/asyncData";
import {ref} from "vue";
import moment from "moment";
import downloadIcon from '@material-design-icons/svg/round/download.svg'
import ShareComp from '@/components/basic/ShareComp.vue'

interface propsType {
  hash: string
}
const props = defineProps<propsType>()
const config = configStore()

const viewerOptions = {
  url: 'data-src',
  navbar: false,
  title: false,
  toolbar: false,
  rotatable: false,
  minZoomRatio: 0.1,
  maxZoomRatio: 2,
  zoomRatio: 0.5
}

const loaded = ref<boolean>(false)

let screenshot = reactive({})
let routine = reactive({})

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

try {
  $Progress.start()
  const result = await get('share', {hash: props.hash})
  console.log(result)
  screenshot = result.data.screenshot
  routine = result.data.routine
  $Progress.finish()
}catch (e) {
  $Progress.fail()
  console.log(e)
}

loaded.value = true

</script>

<template>
  <div class="flex justify-center items-start">
    <NavBarLandingComp />
    <div class="flex flex-col justify-center items-start max-w-[1100px] w-[96%] mt-20 gap-5 text-secondary-900" v-if="screenshot.id">

        <div class="w-full flex flex-row justify-between">
          <div class="flex flex-col">
            <div class="text-2xl font-bold text-start ">
              {{routine.name}}
            </div>
            <div class="flex text-sm gap-4">
              <div class="text-start">
                <span class="font-bold">URL: </span><a :href="routine.path">{{routine.path}}</a>
              </div>
              <div>{{moment.unix(screenshot.createdAt / 1000).format('DD MMM yyyy, zz HH:mm')}}</div>
            </div>
          </div>
          <div class="flex flex-row pt-4 gap-4">
            <div class="group relative">
              <svg class="w-6 h-6 text-gray-200 hover:text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 4.5C15.75 2.84315 17.0931 1.5 18.75 1.5C20.4069 1.5 21.75 2.84315 21.75 4.5C21.75 6.15685 20.4069 7.5 18.75 7.5C17.8933 7.5 17.1212 7.14074 16.5751 6.56624L8.15392 11.2447C8.21665 11.4863 8.25 11.7395 8.25 12C8.25 12.2605 8.21665 12.5137 8.15392 12.7553L16.5751 17.4338C17.1212 16.8593 17.8933 16.5 18.75 16.5C20.4069 16.5 21.75 17.8431 21.75 19.5C21.75 21.1569 20.4069 22.5 18.75 22.5C17.0931 22.5 15.75 21.1569 15.75 19.5C15.75 19.2395 15.7833 18.9863 15.8461 18.7447L7.42488 14.0662C6.87885 14.6407 6.10667 15 5.25 15C3.59315 15 2.25 13.6569 2.25 12C2.25 10.3431 3.59315 9 5.25 9C6.10667 9 6.87885 9.35926 7.42488 9.93377L15.8461 5.25532C15.7833 5.01367 15.75 4.76045 15.75 4.5Z" fill="currentColor"></path></svg>
              <share-comp :text="$t('competitor.share', {msg: config.dataURL  +  'share/' + screenshot.hash })" :link="config.dataURL  +  'share/' + screenshot.hash "/>
            </div>
            <a :href="config.dataURL  +  'data/screenshots/' + screenshot.hash + '.jpg'" target="_blank" :download="(routine.name) + moment.unix(screenshot.createdAt / 1000).format('DD MMM YYYY') + '.jpg'">
              <downloadIcon class="w-6 h-6 text-gray-200 hover:text-gray-400" />
            </a>
          </div>
        </div>
        <div class="max-w-full cursor-pointer h-auto mt-2 border border-gray-100 transition-all hover:scale-105 hover:shadow overflow-hidden" v-viewer="viewerOptions">
          <img class="h-auto"  alt="screenshot" :src="config.dataURL  +  'data/screenshots/' + hash + '_thumb.jpg'" :data-src="config.dataURL  +  'data/screenshots/' + hash + '.jpg'"/>
        </div>

        <div class="rounded-lg bg-stripe-billing font-normal text-sm px-6 py-3.5 w-full">
          <span class="font-bold">WebRivals</span> - Fast & easy to automatically monitor every action of your competitors.
          <router-link class="underline font-bold" :to="{name: 'register'}">→ Start Free Trial</router-link>
        </div>
    </div>
    <div class="flex justify-center items-center max-w-[1100px] w-[96%] mt-40" v-else-if="loaded">
      <div class="text-xl font-bold">
        {{ $t('notFound.title') }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
