<script setup lang="ts">
import moreVert from '@material-design-icons/svg/round/more_vert.svg'
import { routineNewsletterType, routineType } from '@/interfaces/competitors'

const emits = defineEmits(['changedRoutine', 'editRoutine'])
interface propsType {
  active?: boolean,
  routine: routineType | routineNewsletterType
}
const props = defineProps<propsType>()

</script>

<template>
  <div
    class="cursor-pointer h-[70px] p-4 border-gray-25 rounded-xl flex flex-row justify-around items-center text-black dark:text-white border hover:bg-gray-10 dark:hover:bg-gray-600"
    :class="{'border-secondary-500': active, 'opacity-25': routine.frozen}"
    @click="() => {if(!routine.frozen)emits('changedRoutine', routine.id)}"
  >
    <div class="flex flex-col justify-center items-start flex-grow overflow-hidden">
      <div class="text-base font-bold text-ellipsis w-full">
        {{ routine.name }}
      </div>
      <div class="text-xs truncate w-full">
        {{ routine.path ?? routine.email }}
      </div>
    </div>
    <div class="flex justify-start items-center flex-grow-0" @click="() => {if(!routine.frozen)emits('editRoutine', routine.id)}">
      <span class="mr-2 text-lg" :class="routine.active && !routine.frozen ? 'text-routine-play' : 'text-routine-pause'" v-if="routine.path">&#x2022;</span>
      <moreVert class="w-6 h-6 text-gray-200"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
