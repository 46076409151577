<script setup lang="ts">
import { authStore } from '@/stores/authStore'
import { userLogin } from '@/interfaces/auth'
import { useRouter } from 'vue-router'
import { getCurrentInstance, ref } from 'vue'
import InfoComp from '@/components/basic/InfoComp.vue'
import PasswordComp from '@/components/forms/PasswordComp.vue'

const internalInstance = getCurrentInstance()
const $Progress = internalInstance?.appContext.config.globalProperties.$Progress

const router = useRouter()

const user: userLogin = {
  email: '',
  password: ''
}
const login = ():void => {
  $Progress.start()
  auth.login(user)
    .then(async () => {
      $Progress.finish()
      await router.push('/dashboard')
    })
    .catch(e => {
      status.value = 'auth.login.error'
      $Progress.fail()
    })
}

const auth = authStore()

const status = ref<string>('')

</script>

<template>
  <div class="flex flex-col w-full mb-4">
    <button class="btn secondary mb-1.5" @click="$router.push({name: 'register'})">{{ $t('auth.registration.registerNow') }}</button>
    <div class="flex flex-row justify-evenly w-full py-6">
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
      <div class="px-8 uppercase font-normal text-gray-300 text-sm">{{ $t('basic.or')}}</div>
      <div class="border-b border-gray-100 w-full mb-2.5"></div>
    </div>
    <div class="flex justify-center items-center mb-5 text-xl font-bold">
      {{ $t('auth.login.title') }}
    </div>
    <label>
      {{ $t('form.email') }}
    </label>
    <input type="text" v-model="user.email">
    <label>
      {{ $t('form.password') }}
    </label>
    <password-comp @enter="login" @typed="typed => {user.password = typed}" />
  </div>
  <info-comp class="error mb-2" :message="$t(status)" v-if="status === 'auth.login.error'"/>
  <div class="flex flex-row justify-evenly w-full">
    <button class="btn w-full" @click="login">{{ $t('auth.login.loginNow') }}</button>
  </div>
  <div class="flex flex-row justify-evenly w-full pt-4">
    <span class="text-sm font-semibold hover:underline hover:cursor-pointer" @click="$router.push({name: 'forgotPassword'})">{{ $t('auth.login.forgot') }}?</span>
  </div>
</template>

<style scoped lang="scss">

</style>
