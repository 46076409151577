<script setup lang="ts">
import { authStore } from '@/stores/authStore'

const auth = authStore()
</script>

<template>
  <section class="navigation z-50">
    <div class="nav-inner">
      <router-link :to="{name: 'home'}" class="nav-link w-inline-block"><img src="images/webrivals-brand-color.png" loading="lazy" sizes="188.3125px" srcset="images/webrivals-brand-color-p-500.png 500w, images/webrivals-brand-color-p-800.png 800w, images/webrivals-brand-color-p-1080.png 1080w, images/webrivals-brand-color.png 1177w" alt="" class="nav-link-brand"></router-link>
      <div class="nav-btnwrapper">
        <router-link :to="{name: 'features'}" class="nav-button-w w-button">Features</router-link>
        <router-link :to="{name: 'pricing'}" class="nav-button-w w-button">Pricing</router-link>
        <router-link :to="{name: 'resources'}" class="nav-button-w w-button">Resources</router-link>
        <router-link :to="{name: 'dashboard'}" class="nav-button-b w-button" v-if="auth.isAuth">Dashboard</router-link>
        <router-link :to="{name: 'login'}" class="nav-button-c w-button" v-if="!auth.isAuth">Login</router-link>
        <router-link :to="{name: 'register'}" class="nav-button-b w-button" v-if="!auth.isAuth">Start free trial</router-link>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
